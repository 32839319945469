/* eslint-disable */

export default {
  install(Vue, options) {
    //console.log('Logging enabled')
    let logSomme = (type, msg) => {
      /*if (console[type] !== undefined)
        console[type](msg);
      else
        */
      if (`${type||''}` === '') return;
      if (`${type||''}` === '') return;
      if (`${msg||''}` === '') return;
      if (`${msg||''}` === '') return;
      console.log(msg);

      Vue.prototype.$eventBus.emit('web-log', {
        severity: type,
        message: msg
      });
      Vue.prototype.$eventBus.emit('ws-send', {
        action: 'client-log',
        data: {
          severity: type,
          message: msg
        }
      });
    }
    Vue.prototype.$log = {
      log: (msg) => logSomme('client', msg),
      info: (msg) => logSomme('info', msg),
      error: (msg) => logSomme('error', msg),
      warn: (msg) => logSomme('warning', msg),
    }
  }
};
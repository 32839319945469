import { whatsapp, forms } from "./routerConfig";

export default [
  {
    path: ":serviceId/WhatsappChat/:profileId",
    name: "communication-whatsapp-chat-profile",
    meta: {
      ...whatsapp,
      menuAssistantPath: "/Communication/WhatsappChat",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/WhatsappChat`;
      },
      wsSub: ["communication-whatsapp"],
    },
    component: () => import("@/views/pages/communication/whatsapp.vue"),
  },
  {
    path: ":serviceId/WhatsappChat",
    name: "communication-whatsapp-chat",
    meta: {
      ...whatsapp,
      menuAssistantPath: "/Communication/WhatsappChat",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/WhatsappChat`;
      },
      wsSub: ["communication-whatsapp"],
    },
    component: () => import("@/views/pages/communication/whatsapp.vue"),
  },
  {
    path: "/Communication/WhatsappChat",
    name: "communication-whatsapp-chat-base",
    meta: {
      ...whatsapp,
      menuAssistantPath: "/Communication/WhatsappChat",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/WhatsappChat`;
      },
    },
    component: () => import("@/views/no-services-base.vue"),
  },

  {
    path: ":serviceId/Forms",
    name: "communication-forms",
    meta: {
      ...forms,
      menuAssistantPath: "/Communication/Forms",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/Forms`;
      },
      wsSub: ["communication-forms"],
    },
    component: () => import("@/views/pages/communication/forms.vue"),
  },
  /*{
    path: ":serviceId/Forms/:formId",
    name: "communication-form-responses",
    meta: {
      ...forms,
      menuAssistantPath: "/Communication/Forms",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/Forms`;
      },
      wsSub: ["communication-forms", "communication-form-responses"],
    },
    component: () => import("@/views/pages/communication/forms.vue"),
  },*/
  {
    path: ":serviceId/Forms/:formId/results",
    name: "communication-form-responses",
    meta: {
      ...forms,
      menuAssistantPath: "/Communication/Forms",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/Forms`;
      },
      wsSub: ["communication-forms", "communication-form-responses"],
    },
    component: () => import("@/views/pages/communication/formResults.vue"),
  },
  {
    path: "/Communication/Forms",
    name: "communication-forms-base",
    meta: {
      ...forms,
      menuAssistantPath: "/Communication/Forms",
      routeChange(self, serviceId) {
        return `/Communication/${serviceId}/Forms`;
      },
    },
    component: () => import("@/views/no-services-base.vue"),
  },
];

const extraMeta = {
  serviceSelector: {
    storageKey: "mobile",
    services: ['mobileManager'],
    label: "Select Mobile Service",
    autoSelectFirstService: true,
    nameFunc(self, service) {
      let propsList = service.note.split(" ");

      let domainname = null;
      for (let prop of propsList) {
        if (prop.indexOf("NAME:") === 0) {
          domainname = prop.split(":")[1].trim();
          break;
        }
      }

      if (self.$tools.isNullOrUndefined(domainname)) return null;
      return domainname;
    }
  }
};

export default [
  {
    path: ":serviceId/SIMCards",
    name: "bettermobile-simcards",
    meta: {
      noServiceName: 'Mobile SIM Cards',
      ...extraMeta,
      menuAssistantPath: "/Mobile/SIMCards",
      routeChange(self, serviceId) {
        return `/Mobile/${serviceId}/SIMCards`;
      }
    },
    component: () => import("@/views/pages/bettermobile/simcards.vue")
  },
  {
    path: ":serviceName",
    name: "bettermobile-base",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Mobile",
      routeChange(self, serviceId) {
        return `/Mobile/${serviceId}/${self.$route.params.serviceName}`;
      }
    },
    component: () => import("@/views/no-services-base.vue")
  }
];

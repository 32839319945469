const extraMeta = {
  name: 'Device Management',
  serviceSelector: {
    storageKey: "networks",
    services: ['networkManager'],
    label: "Select Network Service",
    autoSelectFirstService: true,
    nameFunc(self, service) {
      if (`${service.note}`.indexOf("NAME:") < 0) return null;
      let propsList = `${service.note}`.split(" ");

      if (propsList.length < 2) return null;

      let domainname = null;
      for (let prop of propsList) {
        if (prop.indexOf("NAME:") === 0) {
          domainname = prop.split(":")[1].trim();
          break;
        }
      }

      if (self.$tools.isNullOrUndefined(domainname)) return null;
      return domainname;
    }
  }
};

export default [{
    path: ":serviceId/Devices/:deviceId",
    name: "betternetworks-device",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Devices",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Devices`;
      },
      wsSub: ["networks-tr069-device-informed", "networks-tr069-device-added", "networks-tr069-device-adopted", "networks-template-added", "networks-template-updated"]
    },
    component: () => import("@/views/pages/betternetworks/devices.item.vue")
  },
  {
    path: ":serviceId/OverviewDashboard",
    name: "betternetworks-dashboard-overview",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/OverviewDashboard",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/OverviewDashboard`;
      },
      wsSub: ["networks-tr069-device-informed", "networks-tr069-device-added", "networks-tr069-device-adopted", "networks-device-updated", "networks-device-added", "networks-device-removed"]
    },
    component: () => import("@/views/pages/betternetworks/dashboards/default.vue")
  },
  {
    path: ":serviceId/SecurityDashboard",
    name: "betternetworks-dashboard-security",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/SecurityDashboard",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/SecurityDashboard`;
      },
      //wsSub: ["networks-tr069-device-informed", "networks-tr069-device-added", "networks-tr069-device-adopted", "networks-device-updated", "networks-device-added", "networks-device-removed"]
    },
    component: () => import("@/views/pages/betternetworks/dashboards/security.vue")
  },
  {
    path: ":serviceId/Devices",
    name: "betternetworks-devices",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Devices",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Devices`;
      },
      wsSub: ["networks-tr069-device-informed", "networks-tr069-device-added", "networks-tr069-device-adopted","networks-log-added", "networks-device-updated", "networks-device-added", "networks-device-removed"]
    },
    component: () => import("@/views/pages/betternetworks/devices.vue")
  },
  {
    path: ":serviceId/Templates",
    name: "betternetworks-templates",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Templates",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Templates`;
      }
    },
    component: () => import("@/views/pages/betternetworks/templates.vue")
  },
  {
    path: ":serviceId/Gateways",
    name: "betternetworks-gateways",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Gateways",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Gateways`;
      }
    },
    component: () => import("@/views/pages/betternetworks/gateways.vue")
  },
  {
    path: ":serviceId/Groups",
    name: "betternetworks-groups",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Groups",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Groups`;
      }
    },
    component: () => import("@/views/pages/betternetworks/groups.vue")
  },
  {
    path: ":serviceId/Templates/:templateId/:draftId?",
    name: "betternetworks-template",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Templates",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Templates`;
      }
    },
    component: () => import("@/views/pages/betternetworks/templates.item.vue")
  },
  // {
  //   path: ":serviceId/Tools/:toolName?",
  //   name: "betternetworks-tools",
  //   meta: {
  //     ...extraMeta,
  //     menuAssistantPath: "/Networks/Tools",
  //     routeChange(self, serviceId) {
  //       if (self.$tools.isNullOrUndefined(self.$route.params.toolName))
  //         return `/Networks/${serviceId}/Tools/Ping`;
  //     }
  //   },
  //   component: () => import("@/views/pages/betternetworks/tools.vue")
  // },
  {
    path: ":serviceId/Syslog/:logId?",
    name: "betternetworks-syslog",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Syslog",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Syslog`;
      }
    },
    component: () => import("@/views/pages/betternetworks/syslogs.vue")
  },
  {
    path: ":serviceId/Services",
    name: "betternetworks-services",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks/Services",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/Services`;
      }
    },
    component: () => import("@/views/pages/betternetworks/services.vue")
  },
  {
    path: ":serviceName",
    name: "betternetworks-base",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Networks",
      routeChange(self, serviceId) {
        return `/Networks/${serviceId}/${self.$route.params.serviceName}`;
      }
    },
    component: () => import("@/views/no-services-base.vue")
  }
  /*, {
    path: ":serviceId/Admin/Types",
    name: "betternetworks-admin-types",
    component: () => import("@/views/pages/betternetworks/admin/types.vue")
  }, {
    path: ":serviceId/Admin/Types/:typeId",
    name: "betternetworks-admin-type",
    component: () => import("@/views/pages/betternetworks/admin/types.edit.vue")
  }, {
    path: ":serviceId/Admin/Templates",
    name: "betternetworks-admin-templates",
    component: () => import("@/views/pages/betternetworks/admin/templates.vue")
  }, {
    path: ":serviceId/Admin/Templates/:templateId?/NewBase",
    name: "betternetworks-admin-template-new-base",
    component: () => import("@/views/pages/betternetworks/admin/templates.new.vue")
  }, {
    path: ":serviceId/Admin/Templates/:templateId",
    name: "betternetworks-admin-template",
    component: () => import("@/views/pages/betternetworks/admin/templates.edit.vue")
  }*/
];
const extraMeta = {
  name: "Tool",
  storageKey: "tools",
  //services: ['tools'],
  label: "Select Tool Service",
  autoSelectFirstService: true,
  nameFunc(self, service) {
    if (`${service.note}`.indexOf("NAME:") < 0) return null;
    let propsList = `${service.note}`.split(" ");

    if (propsList.length < 2) return null;

    let domainname = null;
    for (let prop of propsList) {
      if (prop.indexOf("NAME:") === 0) {
        domainname = prop.split(":")[1].trim();
        break;
      }
    }

    if (self.$tools.isNullOrUndefined(domainname)) return null;
    return domainname;
  },
};

export default [
  {
    path: ":serviceId/RemoveBG",
    name: "tool-removebg",
    meta: {
      menuAssistantPath: "/Tools/RemoveBG",
      routeChange(self, serviceId) {
        return `/Tools/${serviceId}/RemoveBG`;
      },
      wsSub: [],
      serviceSelector: {
        ...extraMeta,
        services: ["toolsRemoveBG"],
      },
    },
    component: () => import("@/views/pages/tools/dash.vue"),
  },
  {
    path: ":serviceName",
    name: "tool-base",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Tools",
      routeChange(self, serviceId) {
        return `/Tools/${serviceId}/${self.$route.params.serviceName}`;
      },
      serviceSelector: {
        ...extraMeta,
        services: ["toolsRemoveBG"],
      },
    },
    component: () => import("@/views/no-services-base.vue"),
  },
];

/* eslint-disable */

import VueNativeSock from "vue-native-websocket";
//import JwtService from "@/common/jwt.service";
/*import store, {
  UPDATE_LAYOUT_CONFIG_FROM_BROWSER
} from '@/store/config.module'*/

let holdingCach = [];
let _holdLock = [];
const addToHoldingCache = async (msg) => {
  if (holdingCach.length > 50) {
    let lockCach = await unlockCach();
    holdingCach.splice(0, 1);
    _holdLock.splice(_holdLock.indexOf(lockCach));
  }
  holdingCach.push(msg);
}
let dimpleDely = (timeMS) => new Promise((resolve) => {
  setTimeout(resolve, timeMS);
});
let unlockCach = () => new Promise(async (resolve) => {
  let tCach = `${new Date().getTime()}.${_holdLock.length}.${holdingCach.length}`;
  _holdLock.push(tCach);
  while (_holdLock.length > 1 && _holdLock[0] !== tCach)
    await dimpleDely(100);
  resolve(tCach);
});

let knowAuthState = false;
let serverAuthenticated = false;
let serverAuthenticatedClient = false;
let sessionId = null;
let lastSessionUpdate = 0;
let serverConnected = false;
let knownSubscriptions = [];
let defaultSubscriptions = ['crm-event', 'portal-notification'];
let reconnectWatcher = null;
let connectedPinr = null;

export default {
  install(Vue, options) { // eslint-disable-line no-unused-vars
    const defaultServerUrl = 'wx://localhost:-1';
    let serverUrl = `${defaultServerUrl}`;
    Vue.use(
      VueNativeSock,
      //CONFIG().WS_URL
      //store.state.config.servers.ws.url, {
      serverUrl, {
        connectManually: true,
        reconnection: false, // (Boolean) whether to reconnect automatically (false)
        //reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
        //reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
      }
    );
    Vue.prototype.$wsSessionId = () => {
      //console.log('get session: ' , sessionId)
      return sessionId;
    };
    const eventBUS = Vue.prototype.$eventBus;
    window.onblur = function() {
      eventBUS.emit('page-focus', false);
    }
    window.onfocus = function() {
      eventBUS.emit('page-focus', true);
    }
    eventBUS.on('cron-minute', () => {
      let diffMS = new Date().getTime() - lastSessionUpdate;
      if (Vue.prototype.$socket === undefined || Vue.prototype.$socket.readyState !== 1 || typeof Vue.prototype.$socket.send !== 'function' || !serverConnected) {
        if (diffMS > 3600000) {
          window.location.reload(true);
        }
        return;
      }
      if (diffMS > 240000) {
        Vue.prototype.$disconnect();
      }
    });
    eventBUS.on('page-navigation', (toPage) => {
      //console.log(`NAVTO_SUB: ${JSON.stringify(toPage)}`);
      if (toPage === undefined || toPage === null) return SendSubState([]);
      if (toPage.meta === undefined || toPage.meta === null) return SendSubState([]);
      if (toPage.meta.wsSub === undefined || toPage.meta.wsSub === null) return SendSubState([]);
      //console.log(`NAVTO_SUB: ${JSON.stringify(toPage.meta.wsSub)}`);
      SendSubState(toPage.meta.wsSub);
    });
    eventBUS.on('ws-connect', (server) => {
      serverUrl = server;
      connectToServer();
    });
    eventBUS.on('ws-fdisconnect', () => {
      serverAuthenticated = false;
      serverConnected = false;
      Vue.prototype.$log.warn('[WS] Force Disconnected');
      eventBUS.emit('ws-state', false);
      Vue.prototype.$disconnect();
    });
    eventBUS.on('ws-client-changed', (clientId) => {
      let nClientId = clientId || false;
      if (serverAuthenticatedClient === nClientId) return;
      serverAuthenticatedClient = nClientId;
      SendSubState([]);
    });
    eventBUS.on('ws-auth', (authToken) => {
      if (knowAuthState !== authToken) {
        knowAuthState = authToken;
        serverAuthenticated = false;
        if (authToken === false) {
          Vue.prototype.$disconnect();
          return;
        }
        sendAuth();
      }
    });
    const sendAuth = () => {
      if (Vue.prototype.$socket === undefined) {
        if (Vue.prototype.$log === undefined)
          return;
        return; //Vue.prototype.$log.warn('[WS] Not ready yet');
      }
      if (Vue.prototype.$socket.readyState !== 1) return;
      if (knowAuthState === false) return;

      Vue.prototype.$socket.send(JSON.stringify({
        'action': 'auth',
        'data': true,
        'auth': knowAuthState
      }));

    }
    /*const SendClientId = () => {
      if (serverAuthenticated === false) return;
      SendToSocket({
        'action': 'SETCLIENT',
        'data': serverAuthenticatedClient,
        //'auth': knowAuthState
      });
    };*/
    const SendSubState = (subscripts) => {
      let subTo = [];
      if (!Vue.prototype.$tools.isArray(subscripts)) {
        subTo = defaultSubscriptions.concat(knownSubscriptions).join(',');
      } else {
        knownSubscriptions = subscripts;
        for (let defaultS of defaultSubscriptions) {
          if (knownSubscriptions.indexOf(defaultS) >= 0) {
            knownSubscriptions.splice(knownSubscriptions.indexOf(defaultS), 1);
          }
        }
        subTo = defaultSubscriptions.concat(knownSubscriptions).join(',');
      }
      let clientId = serverAuthenticatedClient || false;
      //if (clientId === false) return;
      SendToSocket({
        'action': 'CLIENTSTATECHANGE',
        'data': {
          clientId: clientId === false ? null : clientId,
          subscribe: subTo
        }
      });
    }
    const SendToSocketWHCACT = (parsed) => {
      if (Vue.prototype.$socket === undefined || Vue.prototype.$socket.readyState !== 1) {
        return //console.log('DROP:', parsed)
      }
      if (parsed.action === undefined || parsed.action === null) {
        return //console.log('DROP:', parsed)
      }
      if (parsed.data === undefined) {
        return //console.log('DROP:', parsed)
      }
      parsed.auth = knowAuthState;
      const unparsed = JSON.stringify(parsed);
      //console.log(`A_SEND: ${unparsed}`)
      // if (['SUBSCRIBESTATE','ws-ping','client-log'].indexOf(parsed.action) < 0) {
      //   return console.log('DROP:', parsed)
      // }
      Vue.prototype.$socket.send(unparsed)
    }
    const SendToSocket = async (msgTT) => {
      if (Vue.prototype.$socket === undefined || Vue.prototype.$socket.readyState !== 1 || typeof Vue.prototype.$socket.send !== 'function' || _holdLock.length > 1 || !serverConnected) {
        await addToHoldingCache(msgTT);
        return;
      }
      /*if ((holdingCach.length > 0 && _holdLock.length !== 0)) {
        await addToHoldingCache(msgTT);
      }*/
      if (holdingCach.length > 0) {
        let lockCach = await unlockCach();
        while (holdingCach.length > 0) {
          let toDoHeld = holdingCach.splice(0, 1)[0];
          if (['SUBSCRIBESTATE', 'ws-ping', 'auth', 'client-log'].indexOf(toDoHeld.action) >= 0) continue;
          SendToSocketWHCACT(toDoHeld);
        }
        _holdLock.splice(_holdLock.indexOf(lockCach));
      }
      SendToSocketWHCACT(msgTT);
    }
    eventBUS.on('ws-send', async (msg) => {
      //console.log(`SEND:`, msg)
      SendToSocket({
        'action': msg.action.replace('ws-', ''),
        'data': msg.data
      });
    });
    eventBUS.emit('ws-state', false);
    const memoryPing = () => {
      let nav = {};
      if (window.navigator !== null && window.navigator !== null) {
        nav.appCodeName = window.navigator.appCodeName;
        nav.appName = window.navigator.appName;
        nav.appVersion = window.navigator.appVersion;
        nav.buildID = window.navigator.buildID;
        nav.cookieEnabled = window.navigator.cookieEnabled;
        nav.doNotTrack = window.navigator.doNotTrack;
        nav.hardwareConcurrency = window.navigator.hardwareConcurrency;
        nav.language = window.navigator.language;
        nav.maxTouchPoints = window.navigator.maxTouchPoints;
        nav.onLine = window.navigator.onLine;
        nav.oscpu = window.navigator.oscpu;
        nav.platform = window.navigator.platform;
        nav.product = window.navigator.product;
        nav.productSub = window.navigator.productSub;
        nav.userAgent = window.navigator.userAgent;
        nav.vendor = window.navigator.vendor;
        nav.vendorSub = window.navigator.vendorSub;
        nav.webdriver = window.navigator.webdriver;
      }
      SendToSocket({
        action: 'ws-ping',
        data: {
          navigator: nav,
          time: new Date().getTime(),
          page: window.location,
          session: Vue.prototype.$_boot
        }
      })
      SendSubState();
    }
    const connectToServer = () => {
      Vue.prototype.$log.warn('[WS] Attempt Connect');
      Vue.prototype.$connect(serverUrl);
      Vue.prototype.$socket.onclose = (eventMessage) => {
        serverAuthenticated = false;
        serverConnected = false;
        clearInterval(connectedPinr);
        connectedPinr = null;
        sessionId = null;
        //console.log('clear session: ' , sessionId)
        eventBUS.emit('ws-session', sessionId);
        Vue.prototype.$log.warn('[WS] Disconnected');
        eventBUS.emit('ws-state', false);
        Vue.prototype.$disconnect();
        delete Vue.prototype.$socket;
        if (reconnectWatcher === null && serverUrl !== defaultServerUrl)
          reconnectWatcher = setInterval(connectToServer, 10000);
      };
      Vue.prototype.$socket.onerror = (eventMessage) => {
        Vue.prototype.$log.error('[WS] Error');
      };
      Vue.prototype.$socket.onreconnect = (eventMessage) => {
        Vue.prototype.$log.warn('[WS] Re-Connected');
        serverAuthenticated = false;
        serverConnected = true;
        eventBUS.emit('ws-state', true);

        //SendSubState();
      };
      Vue.prototype.$socket.onopen = (eventMessage) => {
        clearInterval(reconnectWatcher);
        connectedPinr = setInterval(memoryPing, 30000);
        reconnectWatcher = null;
        serverConnected = true;
        Vue.prototype.$log.warn('[WS] Connected');

        //SendSubState();
        memoryPing();
        eventBUS.emit('ws-state', true);
        sendAuth();
      };
      Vue.prototype.$socket.onmessage = (eventMessage) => {
        if (eventMessage === undefined || eventMessage === null)
          return Vue.prototype.$log.error('[WS] Events are weird messages');

        if (eventMessage.type !== 'message')
          return;
        if (eventMessage.data === undefined || eventMessage.data === null)
          return Vue.prototype.$log.error('[WS] They`re sending me weird messages');

        let message = null;
        try {
          message = JSON.parse(eventMessage.data);
          //console.log(message)
        } catch (Exc) {
          return Vue.prototype.$log.error('They`re sending me weird messages (garbage [' + eventMessage.data + '])');
        }

        if (message.action === undefined || message.action === null)
          return Vue.prototype.$log.error('They`re sending me weird messages (no action)');
        if (message.data === undefined || message.data === null)
          return Vue.prototype.$log.error('They`re sending me weird messages (no data)');

        if (message.action === "log") {
          if (message.data === 'Hello Flightless Bird') {
            sendAuth();
          }
          if (message.data === 'UNAuthenticated') {
            setTimeout(() => {
              sendAuth();
            }, 1000);
          }
          if (message.data === 'Authenticated') {
            setTimeout(() => {
              serverAuthenticated = true;
              SendSubState();
            }, 5000);
          }
          return; // Vue.prototype.$log.info(`[WS] Log: ${message.data}`);
        }
        if (message.action === "session") {
          sessionId = `${message.data || ''}`;
          if (sessionId.length < 10)
            sessionId = null;
          lastSessionUpdate = new Date().getTime();
          //console.log('set session: ' , sessionId)
          eventBUS.emit('ws-session', sessionId);
          return; // Vue.prototype.$log.info(`[WS] Log: ${message.data}`);
        }

        eventBUS.emit(`ws-${message.action.toLowerCase()}`, message.data);
      };
    };
    //memoryPing();
    //connectToServer();
  },
};
let _eventBus = null;
let cron_SECONDS = null;
let cron_30SECONDS = null;
let cron_MINUTES = null;
let cron_30MINUTES = null;
let cron_HOURS = null;

export default {
  install(Vue) {
    // eslint-disable-line no-unused-vars
    _eventBus = new Vue();
    Vue.prototype.$eventBus = this;
    cron_SECONDS = setInterval(() => {
      Vue.prototype.$eventBus.emit("cron-second", 0);
    }, 1000);
    cron_30SECONDS = setInterval(() => {
      Vue.prototype.$eventBus.emit("cron-30second", 0);
    }, 30000);
    cron_MINUTES = setInterval(() => {
      Vue.prototype.$eventBus.emit("cron-minute", 0);
    }, 60000);
    cron_30MINUTES = setInterval(() => {
      Vue.prototype.$eventBus.emit("cron-30minute", 0);
    }, 60000 * 30);
    cron_HOURS = setInterval(() => {
      Vue.prototype.$eventBus.emit("cron-hour", 0);
    }, 3600000);
    window.addEventListener('resize', () => {
      Vue.prototype.$eventBus.emit("window-resize");
    });
    _eventBus.$on('eb-event', (d) => {
      (((window || {}).bsb || {}).sendData || (() => {}))(d.action, d.data);
    })
  },

  beforeDispose() {
    clearInterval(cron_SECONDS);
    clearInterval(cron_30SECONDS);
    clearInterval(cron_MINUTES);
    clearInterval(cron_30MINUTES);
    clearInterval(cron_HOURS);
  },

  on(event, handler) {
    //console.log('listen: ', event);
    _eventBus.$on(event, handler);
  },

  onOnce(event, handler) {
    //console.log('listenOnce: ', event);
    _eventBus.$once(event, handler);
  },

  off(event, handler) {
    //console.log('ignore: ', event);
    if (handler) {
      _eventBus.$off(event, handler);
    } else {
      _eventBus.$off(event);
    }
  },

  offAll() {
    //console.log('staaap: ');
    _eventBus.$off();
  },

  emit(event, message) {
    //console.log('emit: ', event);
    _eventBus.$emit(event, message);
    //(((window || {}).bsb || {}).sendData || (() => {}))(event, message);
    /*_eventBus.$emit('eb-event', {
      action: event,
      data: message
    });*/
  }
};
/* eslint-disable */
import MOMENT from 'moment';

export default {
  install(Vue) {
    Vue.prototype.$tools = new Tools();
  }
};

export class Tools {
  async copy(text) {
    await window.navigator.clipboard.writeText(text);
  }
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  hierachialGetAvailibility(listOfObjects, key, parentkey, value) {
    let listToReturn = [];
    for (let thisType of listOfObjects) {
      if (thisType[key] === value) {
        listToReturn.push(thisType);
      } else if (thisType[parentkey] === value) {
        for (let iItem of this.hierachialGetAvailibility(listOfObjects, key, parentkey, thisType[key]))
          listToReturn.push(iItem);
      }
    }
    return listToReturn;
  }
  GetValueFromObjectBasedOnStringPathSearcher(workingObj, stringToGet) {
    if (this.isNullOrUndefined(workingObj)) {
      return null;
    }
    if (this.isNullOrUndefined(stringToGet)) {
      return null;
    }
    let splitted = stringToGet.split(".", 2);
    if (splitted.length === 1) {
      if (splitted[0] === '*') {
        let data = [];
        for (let iI of Object.keys(workingObj)) {
          if (this.isArray(workingObj[iI])) {
            for (let iX of workingObj[iI]) {
              data.push({
                _GVRef: iI,
                ...iX
              });
            }
          } else
            data.push({
              _GVRef: iI,
              ...workingObj[iI]
            });
        }
        return data;
      }
      return workingObj[splitted[0]];
    }
    return this.GetValueFromObjectBasedOnStringPath(workingObj[splitted[0]], stringToGet.replace(splitted[0] + ".", ""));
  }
  GetValueFromObjectBasedOnStringPath(workingObj, stringToGet) {
    if (this.isNullOrUndefined(stringToGet)) {
      return null;
    }

    let finalString = "";
    let splitObj = stringToGet.split(",");
    if (splitObj.length === 1) {
      let retData = this.GetValueFromObjectBasedOnStringPathSearcher(workingObj, stringToGet);
      return this.isNullOrUndefined(retData) ? retData : JSON.parse(JSON.stringify(retData));
    }

    for (let val of splitObj) {
      if (this.isNullOrUndefined(stringToGet)) {
        continue;
      }
      let retData = this.GetValueFromObjectBasedOnStringPathSearcher(workingObj, val);
      let data = this.isNullOrUndefined(retData) ? retData : JSON.parse(JSON.stringify(retData));
      if (this.isUndefined(data)) {
        finalString += val;
      } else {
        finalString += data;
      }
    }
    return finalString;
  }
  mergeObjects(src, against, initialMigration) {
    if (!src)
      return against
    if (!against)
      return src

    if (this.isNullOrUndefined(initialMigration) || initialMigration === true) {
      let clonedObj1 = JSON.parse(JSON.stringify(src))
      let obj2String = JSON.stringify(against)
      let clonedObj2 = JSON.parse(obj2String)
      return this.mergeObjects(clonedObj1, clonedObj2, false)
    }

    if (this.isArray(src) && this.isArray(against)) {
      for (let item of against)
        src.push(item);
      return src;
    }

    for (let prop of Object.keys(against)) {
      let srcProp = src[prop]
      let againstProp = against[prop]
      if (this.isObject(againstProp) && !this.isNullOrUndefined(srcProp))
        src[prop] = this.mergeObjects(srcProp, againstProp, false)
      else
        src[prop] = againstProp
    }

    return src
  }
  StringReplaceWithObject(obj, str) {
    let strToReplace = str;
    if (strToReplace.indexOf('{') >= 0) {
      let strSplt = strToReplace.split('{');
      let strSplt2 = strSplt[1].split('}');
      let newVal = this.GetValueFromObjectBasedOnStringPath(obj, strSplt2[0]);
      strToReplace = strSplt[0] + newVal + strSplt2.splice(1).join('}') + (strSplt.length > 2 ? '{' : '') + strSplt.splice(2).join('{');
      strToReplace = this.StringReplaceWithObject(obj, strToReplace);
    }
    return strToReplace;
  }
  getTimeFromMilliseconds(time) {
    if (time < 1000) {
      return `${time} milliseconds`;
    }
    let seconds = time / 1000;
    if (seconds < 60) {
      return `${seconds} seconds`;
    }
    const minutes = seconds / 60;
    if (minutes < 60) {
      return `${minutes} minutes`;
    }
    const hours = minutes / 60;
    if (hours < 60) {
      return `${hours} hours`;
    }
    return `${hours / 24} days`;
  }
  getServiceStatusInfo(statusCode) {
    switch (statusCode) {
      case 'prepared':
      case 'pending':
        return {
          code: 0,
            text: "Prepared",
            description: "Service currently in prep stage",
            class: "gray",
            icon: "sync_problem"
        };
      case 'active':
        return {
          code: 1,
            text: "Active",
            description: "Service currently active",
            class: "success",
            icon: "check"
        };
      case 'ended':
        return {
          code: 2,
            text: "Ended",
            description: "Service has ended",
            class: "secondary",
            icon: "delete_sweep"
        };
      case 'disabled':
        return {
          code: 2,
            text: "Disabled",
            description: "Service has been disabled",
            class: "secondary",
            icon: "delete_sweep"
        };
      case 'suspended':
        return {
          code: 3,
            text: "Suspended",
            description: "Service currently suspended",
            class: "danger",
            icon: "block"
        };
      case 'blocked':
        return {
          code: 4,
            text: "Prepared/Blocked",
            description: "Service in prep or blocked",
            class: "gray",
            icon: "sync_disabled"
        };
      case 'obsolete':
        return {
          code: 5,
            text: "Obsolete",
            description: "Service is obsolete",
            class: "secondary",
            icon: "delete_sweep"
        };
      case 'deferred':
        return {
          code: 6,
            text: "Deferred",
            description: "Service activation deferred",
            class: "gray",
            icon: "schedule"
        };
      case 'quoted':
        return {
          code: 7,
            text: "Quoted",
            description: "Service has been quoted but not activated",
            class: "gray",
            icon: "how_to_vote"
        };
    }
    return {
      code: -1,
      text: "Unknown",
      class: "info"
    };
  }
  getStatusInfo(statusCode) {
    switch (statusCode) {
      case 0:
        return {
          code: 0,
            text: "Prepared",
            description: "Service currently in prep stage",
            class: "gray",
            icon: "sync_problem"
        };
      case 1:
        return {
          code: 1,
            text: "Active",
            description: "Service currently active",
            class: "success",
            icon: "check"
        };
      case 2:
        return {
          code: 2,
            text: "Ended",
            description: "Service has ended",
            class: "secondary",
            icon: "delete_sweep"
        };
      case 3:
        return {
          code: 3,
            text: "Suspended",
            description: "Service currently suspended",
            class: "danger",
            icon: "block"
        };
      case 4:
        return {
          code: 4,
            text: "Prepared/Blocked",
            description: "Service in prep or blocked",
            class: "gray",
            icon: "sync_disabled"
        };
      case 5:
        return {
          code: 5,
            text: "Obsolete",
            description: "Service is obsolete",
            class: "secondary",
            icon: "delete_sweep"
        };
      case 6:
        return {
          code: 6,
            text: "Deferred",
            description: "Service activation deferred",
            class: "gray",
            icon: "schedule"
        };
      case 7:
        return {
          code: 7,
            text: "Quoted",
            description: "Service has been quoted but not activated",
            class: "gray",
            icon: "how_to_vote"
        };
    }
    return {
      code: -1,
      text: "Unknown",
      class: "info"
    };
  }
  getStatusInfoInvoice(statusCode) {
    switch (statusCode) {
      case 'draft':
        return {
          code: 0,
            state: false,
            text: "Draft",
            description: "Invoice prepared",
            class: "secondary",
            icon: "schedule"
        };
      case 'unpaid':
        return {
          code: 1,
            state: true,
            text: "Unpaid",
            description: "Invoice has funds due",
            class: "warning",
            icon: "warning"
        };
      case 'partial':
        return {
          code: 2,
            state: true,
            text: "Partially Paid",
            description: "Invoice has funds due",
            class: "warning",
            icon: "warning"
        };
      case 'paid':
        return {
          code: 3,
            text: "Paid",
            state: true,
            description: "Invoice has been paid",
            class: "success",
            icon: "check"
        };
      case 'void':
        return {
          code: 4,
            state: false,
            text: "Void",
            description: "Invoice has been voided",
            class: "secondary",
            icon: "delete_sweep"
        };
      case 'processed':
        return {
          code: 5,
            state: false,
            text: "Processed",
            description: "Invoice created from proforma invoice",
            class: "success",
            icon: "check"
        };
      case 'pending':
        return {
          code: 6,
            state: false,
            text: "Pending",
            description: "Invoice pending",
            class: "secondary",
            icon: "schedule"
        };
    }
    return {
      code: -1,
      text: "Unknown",
      class: "gray"
    };
  }
  getServiceDetails(serviceId) {
    switch (serviceId) {
      case 25:
      case 36:
      case 37:
      case 33:
      case 23:
      case 29:
      case 34:
      case 24:
      case 35:
      case 26:
        return {
          name: "Fibre to the X",
            img: "/assets/media/svgs/AdobeStock_286005066 [Converted].svg"
        }
    }
    return {
      name: null,
      img: "/assets/media/svgs/AdobeStock_319153965-IconOnly.svg"
    }
  }
  delay(time = 1000) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, time);
    });
  }
  async waitDelayThenThrow(checkFunc, rejectFunc, time = 1000, timeout = 10) {
    let count = 0;
    const causeTimeout = () => {
      count = timeout + 1;
    };
    while (checkFunc(causeTimeout)) {
      count++;
      if (count > timeout && rejectFunc) {
        rejectFunc("Timeout!");
      }
      if (count > timeout) {
        throw "Timeout!";
      }
      await this.delay(time);
    }
  }
  generateNewGuid() {
    // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object
  }
  isString(value) {
    return typeof value === 'string' || value instanceof String
  }
  isNumber(value) {
    return typeof value === "number" || !isNaN(value);
  }
  isDate(value, matchString = true) {
    return value instanceof Date
    /*
          ? true
          : matchString
            ? this.dateTimeRegex.test(`${value}`)
            : false*/
  }
  isArray(value) {
    return value && typeof value === 'object' && value.constructor === Array
  }
  isFunction(value) {
    return typeof value === 'function'
  }
  isBoolean(value) {
    return typeof value === "boolean";
  }
  isUndefined(value) {
    return typeof value === "undefined";
  }
  isNull(value) {
    return value === null;
  }
  isNullOrUndefined(value) {
    return this.isUndefined(value) || this.isNull(value);
  }
  isSymbol(value) {
    return typeof value === 'symbol'
  }
  formatDate(time, format = "DD/MM/YYYY") {
    if (this.isNullOrUndefined(time)) return "";
    return MOMENT(time).format(format);
  }
  getCurrencySymbol(symbol) {
    switch (symbol.toUpperCase()) {
      case 'USD':
        return '$';
      case 'ZAR':
        return 'R';
      case 'GBP':
        return '£';
    }
    return symbol;
  }
  formatCurrency(amount, decimalPlaces,
    decimalFormatter, thousandsFormatter, symbol) {
    decimalPlaces = this.isNullOrUndefined(decimalPlaces) ? 2 : Math.abs(decimalPlaces);
    decimalFormatter = this.isNullOrUndefined(decimalFormatter) ? "." : decimalFormatter;
    thousandsFormatter = this.isNullOrUndefined(thousandsFormatter) ? " " : thousandsFormatter;
    if (!this.isNumber(amount)) {
      amount = Number.parseFloat(amount);
    }
    const negative = (amount < 0 ? "-" : "");
    amount = Math.abs(amount || 0);
    amount = amount.toFixed(decimalPlaces);
    if (this.isNullOrUndefined(symbol)) {
      symbol = "";
    }
    symbol = this.getCurrencySymbol(symbol);
    const numberValueSplit = amount.split(".");
    let decimalValue = "";
    if (decimalPlaces > 0) {
      decimalValue = decimalFormatter + numberValueSplit[1];
    }
    return symbol +
      " " + negative +
      numberValueSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsFormatter) +
      decimalValue;
  }
  genRandomNumber(min, max) {
    return Math.floor(Math.random() * (+max - +min)) + +min;
  }
  randomStatData() {
    const lengthOfRandomData = 15;
    const minNum = 3;
    const maxNum = 20;
    const respArr = [];
    while (respArr.length < lengthOfRandomData) {
      respArr.push(this.genRandomNumber(minNum, maxNum));
    }
    return respArr;
  }
  GetKeyForFormat(type, amt = 1) {
    switch (type) {
      case "GB": {
        if (amt >= 1000) {
          return "TB";
        }
        return "GB";
      }
      case "MB": {
        if (amt >= 1000) {
          return this.GetKeyForFormat("GB", amt / 1000);
        }
        return "MB";
      }
      case "KB": {
        if (amt >= 1000) {
          return this.GetKeyForFormat("MB", amt / 1000);
        }
        return "MB";
      }
      case "B": {
        if (amt >= 1000) {
          return this.GetKeyForFormat("KB", amt / 1000);
        }
        return "MB";
      }
      case "Gb": {
        if (amt >= 1000) {
          return "Tbps";
        }
        return "Gbps";
      }
      case "Mb": {
        if (amt >= 1000) {
          return this.GetKeyForFormat("Gb", amt / 1000);
        }
        return "Mbps";
      }
      case "Kb": {
        if (amt >= 1000) {
          return this.GetKeyForFormat("Mb", amt / 1000);
        }
        return "Kbps";
      }
      case "b": {
        if (amt >= 1000) {
          return this.GetKeyForFormat("Kb", amt / 1000);
        }
        return "bps";
      }
    }
    return "x";
  }
  GetFormatted(speed, type = "MB", roundTo = 2) {
    while (speed >= 1000) {
      speed = speed / 1000;
    }
    return speed.toFixed(roundTo);
  }
  GetInANiceFormat(speed, type = "MB", roundTo = 2) {
    const key = this.GetKeyForFormat(type, speed);
    while (speed >= 1000) {
      speed = speed / 1000;
    }
    return `${this.GetFormatted(speed, type, roundTo)}${key}`;
  }
  GetNiceNumberFormat(value, fixed = 3) {
    let iValue = Number.parseInt(`${value}`);
    if (Number.isNaN(iValue)) {
      return "0".repeat(fixed);
    }

    let outputString = '';
    for (let fixedUp = fixed - 1; fixedUp > 0; fixedUp--) {
      let fixedCounter = Number.parseInt('1' + ('0'.repeat(fixedUp)));
      if (iValue < fixedCounter) {
        outputString += '0';
      }
    }
    return `${outputString}${iValue}`;
  }
}
<template>
  <v-theme-provider :dark="dark" :light="!dark" root>
    <router-view></router-view>
  </v-theme-provider>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/

// Demo skins (demo1 only)
@import "assets/sass/global/integration/frameworks/vue/skins";

.v-data-table td {
  font-size: 1rem;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
  width: 100% !important;
  padding: 40px !important;
}

.kt-quick-panel .v-menu__content {
  left: 20px !important;
}

.v-icon.spin {
  -webkit-animation: spin 4s infinite linear;
  -moz-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<script>
import { mapGetters } from "vuex";
//import { UPDATE_LAYOUT_CONFIG } from "@/store/config.module";

export default {
  computed: {
    ...mapGetters([
      "layoutConfig",
    ]),
  },
  name: "BCorpPortal",
  data() {
    return {
      dark: true
    };
  },
  mounted() {
    if (this.layoutConfig().brand.self.skin === 'light') {
      this.dark = false;
      this.$vuetify.dark = false;
      this.$vuetify.light = true;
      this.$vuetify.theme.dark = false;
      this.$vuetify.theme.light = true;
    }
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    // const self = this;
    // self.$router.push("/Auth/Load");
    // //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG).then(() => {
    // this.$store.dispatch(UPDATE_LAYOUT_CONFIG).then(() => {
    //   self.$nextTick(() => {
    //     self.$router.push("/Auth/Load");
    //   });
    // });
  },
};
</script>

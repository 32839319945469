export default {
  serviceSelector: {
    defaultUrl: '/Security/CCTV/{ID}/Live',
    storageKey: "bettersecurity-cctv",
    services: [{
      servicePlanId: [95],
      status: [1]
    }],
    label: "Select CCTV Service",
    autoSelectFirstService: true,
  }
};
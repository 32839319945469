import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
//import localForage from "localforage";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

Vue.use(Vuex);

/*const localforageStore = localForage.createInstance({
  name: "bcorp"
});*/

const vuexPersist = new VuexPersist({
  key: "bcorp",
  storage: window.localStorage
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs
  }
});
import extraMeta from './routerConfig'

export default [{
    path: ":serviceId/Live",
    name: "bettertelecoms-live",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Telecoms/Live",
      routeChange(self, serviceId) {
        return `/Telecoms/${serviceId}/Live`;
      }
    },
    component: () => import("@/views/pages/bettertelecoms/live.vue")
  },
  {
    path: ":serviceId/CallDetailRecords",
    name: "bettertelecoms-cdrs",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Telecoms/CallDetailRecords",
      routeChange(self, serviceId) {
        return `/Telecoms/${serviceId}/CallDetailRecords`;
      }
    },
    component: () => import("@/views/pages/bettertelecoms/cdrs.vue")
  },
  {
    path: ":serviceName",
    name: "bettertelecoms-base",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Telecoms",
      routeChange(self, serviceId) {
        return `/Telecoms/${serviceId}/${self.$route.params.serviceName}`;
      }
    },
    component: () => import("@/views/blank-base.vue")
  }
];
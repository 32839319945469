import objectPath from "object-path";
//import config from "@/common/config/layout.config.json";
import axios from "axios";

// action types
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
//export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const UPDATE_LAYOUT_CONFIG = "updateLayoutConfig";
//export const UPDATE_LAYOUT_CONFIG_FROM_BROWSER = "updateLayoutConfigFromBrowser";

// mutation types

export default {
  state: {
    config: {},
    loaded: false
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: state => (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue);
    },
    config: state => {
      return state.config;
    },
    configLoaded: state => {
      return state.loaded;
    },
    tenantId(state) {
      return state.config.tenantId || null;
    },
    appId(state) {
      return state.config.appId || null;
    },
    logoTheme: (state) => () => {
      let skin = objectPath.get(state.config, 'brand.self.skin', 'light')
      if (skin !== 'light')
        return objectPath.get(state.config, 'self.logo.light', null)
      return objectPath.get(state.config, 'self.logo.dark', null)
    },
    theme: (state) => () => {
      return objectPath.get(state.config, 'brand.self.skin', 'light')
    }
  },
  actions: {
    /*[SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload);
    },*/
    /*[UPDATE_LAYOUT_CONFIG_FROM_BROWSER](state) {
      state.commit(SET_LAYOUT_CONFIG, Object.assign({},
        state.config,
        JSON.parse(localStorage.getItem("config"))
      ));
    },*/
    [UPDATE_LAYOUT_CONFIG](state) {
      return new Promise((resolve, reject) => {
        axios.get(/*window.location.protocol === 'http:' ? 'http://127.0.0.1:3002/app' : */'https://whoami.betterportal.cloud/app')
          .then(x => {
            const hasChange = (`${JSON.stringify(x.data)}` != `${JSON.stringify(state.state.config)}`);
            state.commit(SET_LAYOUT_CONFIG, x.data);

            try {
              if (x.data.servers.push !== undefined && x.data.servers.push.url !== undefined) {
                window.bsb = window.bsb || {}; 
                window.bsb.events = window.bsb.events || {}; 
                window.bsb.events.onReady = () => {
                  window.bsb.sendData('SW_APP_CONFIG', x.data);
                };
                
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = x.data.servers.push.url + '/client.js';

                document.head.appendChild(script);
              }
            } catch (e) {
              return resolve(hasChange); // empty catch
            }

            resolve(hasChange);
          }).catch(x => {
            if (x.message === 'Network Error') {
              return reject('Network');
            }
            if (x.response !== undefined && x.response !== null) {
              if (x.response.status === 404) {
                return reject('Tenant');
              }
            }
            //console.error(x);
            reject('Server');
          })
      })
    }
  },
  mutations: {
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload;
      state.loaded = true;
    }
    /*,
        [UPDATE_LAYOUT_CONFIG_FROM_BROWSER](state) {
          state.config = Object.assign({},
            state.config,
            JSON.parse(localStorage.getItem("config"))
          );
        }*/
  }
};
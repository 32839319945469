const gatewaysCacheKey = "betternetworks:gateways";
const devicesCacheKey = "betternetworks:devices";
const servicesCacheKey = "betternetworks:services";
let VM;
export default {
  initUpdator(self) {
    //console.log('initUpdator')
    let me = this;
    VM = this;
    /*self.$eventBus.on("ws-networks-device-update", async updateData => {
      let devices = await me.getDevices(self, updateData.serviceId);
      let deviceExists = false;
      for (let device of devices) {
        if (device.id === updateData.deviceId) {
          deviceExists = true;
          break;
        }
      }
      if (!deviceExists) {        
        await me.getDevices(self, updateData.serviceId, null, true);
        return self.$eventBus.emit("networks-device-update", updateData);
      }
      /*me.getDevices(self)
        .then(async deviceList => {
          let deviceExists = false;
          for (let device of deviceList) {
            if (device.id === updateData.deviceId) {
              deviceExists = true;
              break;
            }
          }
          await me.getDevices(self, null, true);
          return self.$eventBus.emit('networks-device-update', updateData);
        });* /
      //await me.getDevices(self, updateData.serviceId, null, true);
      return self.$eventBus.emit("networks-device-update", updateData);
    });*/
    self.$eventBus.on("ws-networks-templates-update", (updateData) => {
      //console.log('UPDATE TRIGGER: ' + updateData.id);
      me.getTemplates(self, updateData.serviceId, null, true)
        .then(() => {})
        .catch(() => {});
      me.getTemplates(self, updateData.serviceId, updateData.id, true)
        .then(() => {})
        .catch(() => {});
      if (!self.$tools.isNullOrUndefined(updateData.draftVersion)) {
        //console.log('UPDATE TRIGGER: ' + `${updateData.id}.${updateData.draftVersion}`);
        me.getTemplates(
          self,
          updateData.serviceId,
          `${updateData.id}.${updateData.draftVersion}`,
          true
        )
          .then(() => {})
          .catch(() => {});
      }
    });
    self.$eventBus.on("ws-networks-gateway-syslog-result", (syslog) => {
      let syslogList = [];
      let sysLogData = window.sessionStorage.getItem("syslogs");
      if (!self.$tools.isNullOrUndefined(sysLogData)) {
        syslogList = JSON.parse(sysLogData);
      }
      if (self.$tools.isNullOrUndefined(sysLogData)) {
        return;
      }
      me.getDevices(self, sysLogData.serviceId)
        .then((deviceList) => {
          for (let device of deviceList) {
            if (device.id !== syslog.deviceId) continue;
            return self.$eventBus.emit("notification", {
              value: {
                title: syslog.result.message,
                color:
                  syslog.result.message.indexOf("error") < 0
                    ? "info"
                    : "danger",
                icon: "flaticon-layer",
                postNote: device.name,
                link: `/Networks/${self.$root.clientId}/Syslog/${
                  syslog.gatewayId
                }-${syslog.deviceId}-${syslog.result.date}-${
                  syslog.result.gatewayTime
                }`,
              },
              time: new Date(syslog.result.date).getTime(),
              type: "events",
            });
          }
        })
        .catch((x) => self.$log.error);

      syslogList.push(syslog);

      if (syslogList.length > 100) {
        syslogList.splice(100);
      }
      window.sessionStorage.setItem("syslogs", JSON.stringify(syslogList));

      self.$eventBus.emit("gateway-syslog", syslog);
    });
    self.$eventBus.on("ws-networks-device-status", (status) => {
      self.$cache
        .get(`${devicesCacheKey}-${status.serviceId}`)
        .then((cacheOfDevices) => {
          if (self.$tools.isNullOrUndefined(cacheOfDevices)) return;

          for (let i = 0; i < cacheOfDevices.length; i++) {
            if (cacheOfDevices[i].id === status.deviceId) {
              let statChanged = cacheOfDevices[i].status != status.status;
              cacheOfDevices[i].status = status.status;
              cacheOfDevices[i].lastPing = status.lastPing;
              cacheOfDevices[i].lastIP = status.lastIP;
              cacheOfDevices[i].lastIPPing = status.lastIPPing;
              self.$cache.set(
                `${devicesCacheKey}-${status.serviceId}`,
                cacheOfDevices
              );
              self.$eventBus.emit("networks-device-status", status);
              if (statChanged) {
                let device = cacheOfDevices[i];
                self.$eventBus.emit("notification", {
                  value: {
                    title: `Device ${device.name} is ${
                      status.status ? "online" : "offline"
                    }`,
                    color: status.status ? "success" : "danger",
                    icon: status.status
                      ? "flaticon2-check-mark"
                      : "flaticon2-delete",
                  },
                  type: "events",
                });
                self.$eventBus.emit("notification", {
                  key: `device-${device.id}`,
                  remove: status.status,
                  value: {
                    title: `Device ${device.name} is ${
                      status.status ? "online" : "offline"
                    }`,
                    color: status.status ? "success" : "danger",
                    icon: status.status
                      ? "flaticon2-check-mark"
                      : "flaticon2-delete",
                  },
                  type: "alerts",
                });
              }
              return;
            }
          }
          self.$api
            .getWithCache(
              self,
              "networks",
              "devices",
              `${devicesCacheKey}-${status.serviceId}`,
              false
            )
            .then((devicesList) => {
              self.$eventBus.emit("networks-device-status", status);
              for (let device of devicesList) {
                if (device.status != status.status) {
                  self.$eventBus.emit("notification", {
                    value: {
                      title: `Device ${device.name} is ${
                        status.status ? "online" : "offline"
                      }`,
                      color: status.status ? "success" : "danger",
                      icon: status.status
                        ? "flaticon2-check-mark"
                        : "flaticon2-delete",
                    },
                    type: "events",
                  });
                  self.$eventBus.emit("notification", {
                    key: `device-${device.id}`,
                    remove: status.status,
                    value: {
                      title: `Device ${device.name} is ${
                        status.status ? "online" : "offline"
                      }`,
                      color: status.status ? "success" : "danger",
                      icon: status.status
                        ? "flaticon2-check-mark"
                        : "flaticon2-delete",
                    },
                    type: "alerts",
                  });
                }
                break;
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    });
    self.$eventBus.on("ws-networks-gateway-status", (status) => {
      self.$cache
        .get(gatewaysCacheKey)
        .then((cacheOfServices) => {
          if (self.$tools.isNullOrUndefined(cacheOfServices)) return;

          for (let i = 0; i < cacheOfServices.length; i++) {
            if (cacheOfServices[i].id === status.gatewayId) {
              let statChanged = cacheOfServices[i].status != status.status;
              cacheOfServices[i].status = status.status;
              cacheOfServices[i].lastMessage = status.time;
              self.$cache.set(gatewaysCacheKey, cacheOfServices);
              self.$eventBus.emit("networks-gateway-status", status);
              let gateway = cacheOfServices[i];
              if (statChanged) {
                self.$eventBus.emit("notification", {
                  value: {
                    title: `Gateway ${gateway.name} is ${
                      status.status ? "online" : "offline"
                    }`,
                    color: status.status ? "success" : "danger",
                    icon: status.status
                      ? "flaticon2-check-mark"
                      : "flaticon2-delete",
                  },
                  type: "events",
                });
                self.$eventBus.emit("notification", {
                  key: `gateway-${gateway.id}`,
                  remove: status.status,
                  value: {
                    title: `Gateway ${gateway.name} is ${
                      status.status ? "online" : "offline"
                    }`,
                    color: status.status ? "success" : "danger",
                    icon: status.status
                      ? "flaticon2-check-mark"
                      : "flaticon2-delete",
                  },
                  type: "alerts",
                });
              }
              return;
            }
          }
          self.$api
            .getWithCache(self, "networks", "gateways", gatewaysCacheKey, true)
            .then((gatewayList) => {
              self.$eventBus.emit("networks-gateway-status", status);
              for (let gateway of gatewayList) {
                if (gateway.status != status.status) {
                  self.$eventBus.emit("notification", {
                    value: {
                      title: `Gateway ${gateway.name} is ${
                        status.status ? "online" : "offline"
                      }`,
                      color: status.status ? "success" : "danger",
                      icon: status.status
                        ? "flaticon2-check-mark"
                        : "flaticon2-delete",
                    },
                    type: "events",
                  });
                  self.$eventBus.emit("notification", {
                    key: `gateway-${gateway.id}`,
                    remove: status.status,
                    value: {
                      title: `Gateway ${gateway.name} is ${
                        status.status ? "online" : "offline"
                      }`,
                      color: status.status ? "success" : "danger",
                      icon: status.status
                        ? "flaticon2-check-mark"
                        : "flaticon2-delete",
                    },
                    type: "alerts",
                  });
                }
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    });
  },
  getLayout: (
    self,
    serviceId,
    brand,
    make,
    model,
    firmwareVersion,
    bumpCache = false
  ) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          "networks",
          `${
            self.$root.clientId
          }/${serviceId}/layouts/${brand}/${make}/${model}${
            firmwareVersion !== undefined && firmwareVersion !== null
              ? `/${firmwareVersion}`
              : ""
          }`,
          `betternetworks:layouts-${brand}-${make}-${model}-${firmwareVersion ||
            "na"}`,
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  getTypes: (self, serviceId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          "networks",
          `${self.$root.clientId}/${serviceId}/types`,
          `betternetworks:types:${serviceId}`,
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  getGroups: (self, serviceId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          "networks",
          `${self.$root.clientId}/${serviceId}/groups`,
          `betternetworks:groups:${serviceId}`,
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  getGroupConnection: (self, serviceId, groupId) =>
    new Promise((resolve, reject) => {
      self.$api
        .get(
          "networks",
          `${self.$root.clientId}/${serviceId}/groups/${groupId}/conn`
        )
        .then(resolve)
        .catch(reject);
    }),
  addUpdateGroup: (self, serviceId, id, data) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .post2(
          "networks",
          `${self.$root.clientId}/${serviceId}/groups/${data.gatewayId ||
            "cloud"}${self.$tools.isNullOrUndefined(id) ? "" : `/${id}`}`,
          data
        )
        .then(() => {
          VM.getGroups(self, serviceId, true)
            .then(() => {
              resolve();
              //VM.getDevices( self, self.deviceId, true ).then( resolve ).catch( reject );
            })
            .catch(reject);
        })
        .catch(reject);
    }),
  getTemplates: (self, serviceId, templateId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .getWithCache(
          self,
          "networks",
          `${self.$root.clientId}/${serviceId}/` +
            (templateId !== undefined && templateId !== null
              ? `templates/${templateId}`
              : "templates"),
          `betternetworks:templates-${serviceId}` +
            (templateId !== undefined && templateId !== null
              ? `-${templateId}`
              : ""),
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  hasDevicesCache: (self, serviceId) => {
    return self.$api.hasCache(self, `${devicesCacheKey}-${serviceId}`);
  },
  forceDevicesCacheUpdate: (self, serviceId, arrOfDevices) => {
    self.$api.forceCacheSet(
      self,
      `${devicesCacheKey}-${serviceId}`,
      arrOfDevices
    );
  },
  getDevices: (self, serviceId, deviceId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .getWithCache(
          self,
          "networks",
          `${self.$root.clientId}/${serviceId}/` +
            (deviceId !== undefined && deviceId !== null
              ? `devices/${deviceId}`
              : "devices"),
          `${devicesCacheKey}-${serviceId}${
            deviceId !== undefined && deviceId !== null ? `-${deviceId}` : ""
          }`,
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  getLogs: (self, serviceId, refType, refId) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .get(
          "networks",
          `${self.$root.clientId}/${serviceId}/logs/${refType}/${refId}`
        )
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  saveDevice: (self, serviceId, deviceId, config) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      if (self.$tools.isNullOrUndefined(deviceId)) return reject("NO ID");
      self.$api
        .update2(
          "networks",
          `${self.$root.clientId}/${serviceId}/devices/${deviceId}`,
          null,
          config
        )
        .then(() => {
          VM.getDevices(self, serviceId, null, true)
            .then(() => {
              resolve();
              //VM.getDevices( self, self.deviceId, true ).then( resolve ).catch( reject );
            })
            .catch(reject);
        })
        .catch(reject);
    }),
  rebootDevice: (self, serviceId, deviceId) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      if (self.$tools.isNullOrUndefined(deviceId)) return reject("NO ID");
      self.$api
        .put2(
          "networks",
          `${self.$root.clientId}/${serviceId}/devices/${deviceId}/reboot`,
          {},
          {}
        )
        .then(resolve)
        .catch(reject);
    }),
  syncDevice: (self, serviceId, deviceId) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      if (self.$tools.isNullOrUndefined(deviceId)) return reject("NO ID");
      self.$api
        .put2(
          "networks",
          `${self.$root.clientId}/${serviceId}/devices/${deviceId}/sync`,
          {},
          {}
        )
        .then(resolve)
        .catch(reject);
    }),
  removeDevice: (self, serviceId, deviceId) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      if (self.$tools.isNullOrUndefined(deviceId)) return reject("NO ID");
      self.$api
        .delete(
          `networks/${self.$root.clientId}/${serviceId}/devices/${deviceId}`
        )
        .then(() => {
          VM.getDevices(self, serviceId, null, true)
            .then(() => {
              resolve();
              //VM.getDevices( self, self.deviceId, true ).then( resolve ).catch( reject );
            })
            .catch(reject);
        })
        .catch(reject);
    }),
  // getNewDeviceConfig: (self, serviceId, type, make) =>
  //   new Promise((resolve, reject) => {
  //     if (self.$tools.isNullOrUndefined(serviceId))
  //       return reject("serviceID not defined");
  //     self.$api.get(
  //         `networks/${self.$root.clientId}/${serviceId}/devices/new/${type}/${make}`
  //       )
  //       .then((x) => resolve(x.data))
  //       .catch(reject);
  //   }),
  getGateways: (self, serviceId, gatewayId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .getWithCache(
          self,
          "networks",
          `${self.$root.clientId}/${serviceId}/gateways` +
            (gatewayId !== undefined && gatewayId !== null
              ? `/${gatewayId}`
              : ""),
          `${gatewaysCacheKey}-${serviceId}${
            gatewayId !== undefined && gatewayId !== null ? `-${gatewayId}` : ""
          }`,
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  setTemplates: (
    self,
    serviceId,
    templateId,
    templateConfig,
    draftName,
    draftId
  ) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .post(
          `networks/${
            self.$root.clientId
          }/${serviceId}/templates/${templateId}` +
            (draftName !== undefined && draftName !== null
              ? `/${encodeURIComponent(draftName)}`
              : "") +
            (draftId !== undefined && draftId !== null
              ? `?draftId=${draftId}`
              : ""),
          {
            data: templateConfig,
          }
        )
        .then((x) => {
          VM.getTemplates(self, serviceId)
            .then(async (xtl) => {
              for (let template of xtl) {
                if (template.parentTemplateId === templateId) {
                  self.$cache.del(
                    `betternetworks:templates-${serviceId}-${template.id}`
                  );
                }
              }
              resolve(x);
            })
            .catch(reject);
        })
        .catch(reject);
    }),
  newTemplate: (self, serviceId, templateId, newTemplateName) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .post(
          `networks/${
            self.$root.clientId
          }/${serviceId}/templates/${templateId}`,
          {
            name: newTemplateName,
          }
        )
        .then((newId) => {
          self.$cache.del(`betternetworks:templates-${serviceId}`);
          resolve(newId.data);
        })
        .catch(reject);
    }),
  deleteTemplates: (self, serviceId, templateId, draftId) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .delete(
          `networks/${
            self.$root.clientId
          }/${serviceId}/templates/${templateId}${
            draftId === undefined || draftId === null ? "" : `/${draftId}`
          }`
        )
        .then(() => {
          self.$cache.del(`betternetworks:templates-${serviceId}`);
          self.$cache.del(
            `betternetworks:templates-${serviceId}-${templateId}`
          );
          self.$cache.del(
            `betternetworks:templates-${serviceId}-${templateId}.${draftId}`
          );
          resolve();
        })
        .catch(reject);
    }),
  deployDraft: (self, serviceId, templateId, draftId) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .put(
          `networks/${
            self.$root.clientId
          }/${serviceId}/templates/${templateId}/${draftId}`
        )
        .then(() => {
          VM.getTemplates(self, serviceId, null, true)
            .then(() => {
              VM.getTemplates(self, serviceId, templateId, true)
                .then(resolve)
                .catch(reject);
            })
            .catch(reject);
        })
        .catch(reject);
    }),
  getServices: (self, serviceId, iServiceId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .getWithCache(
          self,
          `networks/${serviceId}`,
          iServiceId !== undefined && iServiceId !== null
            ? `services/${iServiceId}`
            : "services",
          `${servicesCacheKey}-${serviceId}${
            iServiceId !== undefined && iServiceId !== null
              ? `-${iServiceId}`
              : ""
          }`,
          bumpCache
        )
        .then(resolve)
        .catch(reject);
    }),
  setServices: (self, serviceId, iServiceId, config) =>
    new Promise((resolve, reject) => {
      if (self.$tools.isNullOrUndefined(serviceId))
        return reject("serviceID not defined");
      self.$api
        .post(
          `networks/${serviceId}/services` +
            (iServiceId !== undefined && iServiceId !== null
              ? `/${iServiceId}`
              : ""),
          {
            data: config,
          }
        )
        .then((x) => {
          self.$cache.del(`${servicesCacheKey}-${serviceId}`);
          self.$cache.del(`${servicesCacheKey}-${serviceId}-${iServiceId}`);
          VM.getServices(self, serviceId, iServiceId);
          resolve(x);
        })
        .catch(reject);
    }),

  getTemplateConfig: (self, serviceId, templateId) =>
    new Promise((resolve, reject) => {
      self.$api
        .get(
          "networks",
          `${self.$root.clientId}/${serviceId}/tconfig/${templateId}`
        )
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  // getTemplateBaseConfig: (self, templateId, bumpCache = false) => new Promise((resolve, reject) => {
  //   self.$api.getWithCache(self, `networks`, `templates/${templateId}/base`, `betternetworks:templates-${templateId}-config-base`, bumpCache).then(resolve).catch(reject);
  // }),
  // getTemplates: (self, baseOnly = false, bumpCache = false) => new Promise((resolve, reject) => {
  //   self.$api.getWithCache(self, "networks", "templates?baseOnly=" + (baseOnly ? '1' : '0'), `betternetworks:templates${(baseOnly ? '-basetemplates' : '')}`, bumpCache).then(resolve).catch(reject);
  // }),
  // getDevices: (self, bumpCache = false) => new Promise((resolve, reject) => {
  //   self.$api.getWithCache(self, "networks", "devices", "betternetworks:devices", bumpCache).then(resolve).catch(reject);
  // }),
  // addGroupOption: (obj) => new Promise((resolve, reject) => {
  //   self.$api.post("networks/admin/groups/options", obj).then(resolve).catch(reject);
  // }),
  // setGroupOption: (id, obj) => new Promise((resolve, reject) => {
  //   self.$api.put(`networks/admin/groups/options/${id}`, obj).then(resolve).catch(reject);
  // }),
  // addGroup: (obj) => new Promise((resolve, reject) => {
  //   self.$api.put('networks/admin/groups/', obj).then(resolve).catch(reject);
  // }),
  // addTemplate: (parentTemplateId, name, routerTypeId, isBase = false) => new Promise((resolve, reject) => {
  //   if (isBase) {
  //     return self.$api.post(`networks/admin/templates${parentTemplateId !== null ? `/${parentTemplateId}` : ''}`, {
  //       name,
  //       routerTypeId
  //     }).then(resolve).catch(reject);
  //   }
  //   self.$api.post(`networks/templates${parentTemplateId !== null ? `/${parentTemplateId}` : ''}`, {
  //     name,
  //     routerTypeId
  //   }).then(resolve).catch(reject);
  // }),
  // addRouterType: (name, make, model, active = false, parentRouterType = null) => new Promise((resolve, reject) => {
  //   self.$api.post(`networks/admin/routerTypes${parentRouterType !== null ? `/${parentRouterType}` : ''}`, {
  //     name,
  //     make,
  //     model,
  //     active,
  //   }).then(resolve).catch(reject);
  // }),
  // hierachialGetRouterTypeAvailibility(self, routerTypes, routerTypeId) {
  //   return self.$tools.hierachialGetAvailibility(routerTypes, 'id', 'parentRouterType', routerTypeId);
  // }
};

// Germany
export const locale = {
  TRANSLATOR: {
    SELECT: "Wählen Sie Ihre Sprache"
  },
  MENU: {
    NEW: "Neu",
    ACTIONS: "Aktionen",
    CREATE_POST: "Erstellen Sie einen neuen Beitrag",
    PAGES: "Seiten",
    FEATURES: "Eigenschaften",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
		LBUILDER: "Layout Builder",
    SERVICES: "Services",
    BILLING: "Abrechnung",
    INVOICES: "Rechnungen",
    STATEMENT: "Statement",
    DOMAINS_NEW: "Neue Domain",
    DOMAINS: "Domains",
    ROUTERMGNT_DEVICES: "Geräte",
    ROUTERMGNT_TEMPLATES: "Vorlagen",
    ROUTERMGNT_GATEWAYS: "Gateways",
    ROUTERMGNT_CONNECT: "Neues Gerät",
    ROUTERMGNT_CREATE: "Neue Vorlage",
    ROUTERMGNT_TOOLS: "Tools",
    ROUTERMGNT_SYSLOG: "Logging",
    BTX_NEWS: "News",
    BTX_STORE: "Store"
  },
  AUTH: {
    GENERAL: {
      OR: "Oder",
      SUBMIT_BUTTON: "absenden",
      NO_ACCOUNT: "Haben Sie noch kein Benutzerkonto?",
      SIGNUP_BUTTON: "Anmelden",
      FORGOT_BUTTON: "Passwort vergessen",
      BACK_BUTTON: "Zurück",
      PRIVACY: "Datenschutz",
      LEGAL: "Impressum",
      CONTACT: "Kontakt"
    },
    LOGIN: {
      TITLE: "Anmelden",
      BUTTON: "Anmelden"
    },
    FORGOT: {
      TITLE: "Passwort vergessen?",
      DESC: "Geben Sie Ihre hinterlegte Emailadresse ein, um Ihr Passwort zurückzusetzen.",
      SUCCESS: "Ihr Passwort wurde erfolgreich zurückgesetzt."
    },
    REGISTER: {
      TITLE: "Registrieren",
      DESC: "Geben Sie Ihre persönlichen Informationen ein, um ein Benutzerkonto zu erstellen.",
      SUCCESS: "Ihr Benutzerkonto wurde erfolgreich angelegt."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Vollständiger Name",
      PASSWORD: "Passwort",
      CONFIRM_PASSWORD: "Passwort bestätigen",
      USERNAME: "Benutzername"
    },
    VALIDATION: {
      INVALID: "{{name}} ist ungültig",
      REQUIRED: "{{name}} ist erforderlich",
      MIN_LENGTH: "{{name}} hat eine Mindestlänge von {{min}}",
      AGREEMENT_REQUIRED: "Das Akzeptieren der allgemeinen Geschäftsbedingungen ist erforderlich",
      NOT_FOUND: "Das angeforderte Element {{name}} konnte nicht gefunden werden",
      INVALID_LOGIN: "Die Anmeldedaten sind nicht korrekt",
      REQUIRED_FIELD: "Das Feld ist erforderlich",
      MIN_LENGTH_FIELD: "Die Mindestlänge für das Feld beträgt:",
      MAX_LENGTH_FIELD: "Die maximale Feldlänge ist:",
      INVALID_FIELD: "Das Feld ist ungültig"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Anzahl der selektierten Datensätze: ",
      ALL: "Alle",
      SUSPENDED: "gesperrte",
      ACTIVE: "aktiv",
      FILTER: "Filter",
      BY_STATUS: "nach Status",
      BY_TYPE: "nach Typ",
      BUSINESS: "Unternehmen",
      INDIVIDUAL: "Privatperson",
      SEARCH: "Suche",
      IN_ALL_FIELDS: "in allen Feldern"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Kunden",
      CUSTOMERS_LIST: "Kundenliste",
      NEW_CUSTOMER: "Neuer Kunde",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Kundendatensatz löschen",
        DESCRIPTION: "Sind Sie sicher, dass Sie den Kundendatensatz dauerhaft löschen möchten?",
        WAIT_DESCRIPTION: "Der Kundendatensatz wird geslöscht...",
        MESSAGE: "Der Kundendatensatz wurde gelöscht."
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Kundendatensätze löschen",
        DESCRIPTION: "Sind Sie sicher, dass Sie alle augewählten Kundendatensätze dauerhaft löschen möchten?",
        WAIT_DESCRIPTION: "Die Kundendatensätze werden gelöscht...",
        MESSAGE: "Die Kundendatensätze wurden gelöscht."
      },
      UPDATE_STATUS: {
        TITLE: "Der Status der ausgewählten Kundendatensätze wurde aktualisiert",
        MESSAGE: "Der Status der ausgewählten Kundendatensätze wurde erfolgreich aktualisiert"
      },
      EDIT: {
        UPDATE_MESSAGE: "Der Kundendatensatz wurde aktualisiert",
        ADD_MESSAGE: "Der Kundendatensatz wurde erstellt"
      }
    }
  }
};
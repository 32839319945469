export default [{
    name: "Login",
    path: "/Auth/Login",
    component: () => import("@/views/pages/auth/BA_Login"),
    meta: {
      public: true,
      wsSub: ["auth-login-assistant"]
    }
  },
  {
    name: "Login",
    path: "/Auth/Load",
    component: () => import("@/views/pages/auth/BA_Loading"),
    meta: {
      public: true
    }
  },
  {
    name: "Select Client",
    path: "/Auth/Clients",
    component: () => import("@/views/pages/auth/BA_Clients"),
    meta: {
      public: false
    }
  },
  {
    name: "An Error Occurred",
    path: "/Auth/Error/:state",
    component: () => import("@/views/pages/auth/BA_Loading"),
    meta: {
      public: true
    }
  }
]
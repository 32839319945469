// action types
export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export const ADD_CLASSNAME = "addClassName";
// mutation types
export const SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";
export const CLEAR_CLASSNAMES = "clearClassnames";
export const RESET_CLASSNAMES = "resetClassnames";
export const RESET_CLASSNAMES_BASIC = "resetClassnamesBasic";
import HtmlClass from "@/common/htmlclass.service";

export default {
  state: {
    classes: {}
  },
  getters: {
    getClasses: state => position => {
      if (typeof position !== "undefined") {
        return state.classes[position];
      }
      return state.classes;
    }
  },
  actions: {
    [CLEAR_CLASSNAMES]() {
      document.body.classList = [];
    },
    [RESET_CLASSNAMES]() {
      document.body.classList = [];
      HtmlClass.init();
    },
    [RESET_CLASSNAMES_BASIC]() {
      document.body.classList = [];
      HtmlClass.initBasic();
    },
    [ADD_BODY_CLASSNAME](context, className) {
      document.body.classList.add(className);
    },
    [REMOVE_BODY_CLASSNAME](context, className) {
      document.body.classList.remove(className);
    },
    [ADD_CLASSNAME](context, payload) {
      context.commit(SET_CLASSNAME_BY_POSITION, payload);
    }
  },
  mutations: {
    [SET_CLASSNAME_BY_POSITION](state, payload) {
      const {
        position,
        className
      } = payload;
      if (!state.classes[position]) {
        state.classes[position] = [];
      }
      state.classes[position].push(className);
    }
  }
};
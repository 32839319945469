const extraMeta = {
  name: "Web",
  serviceSelector: {
    storageKey: "web",
    services: ["semrushManager"],
    label: "Select Web Service",
    autoSelectFirstService: true,
    nameFunc(self, service) {
      if (`${service.note}`.indexOf("NAME:") < 0) return null;
      let propsList = `${service.note}`.split(" ");

      if (propsList.length < 2) return null;

      let domainname = null;
      for (let prop of propsList) {
        if (prop.indexOf("NAME:") === 0) {
          domainname = prop.split(":")[1].trim();
          break;
        }
      }

      if (self.$tools.isNullOrUndefined(domainname)) return null;
      return domainname;
    },
  },
};

export default [
  {
    path: ":serviceId/SEOReports/:reportId?",
    name: "web-semrush-report",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Web/SEOReports",
      routeChange(self, serviceId) {
        return `/Web/${serviceId}/SEOReports`;
      },
      wsSub: [],
    },
    component: () => import("@/views/pages/betterweb/semrush-report.vue"),
  },
  {
    path: ":serviceId/SEOTasks/:taskId?",
    name: "web-semrush-report",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Web/SEOTasks",
      routeChange(self, serviceId) {
        return `/Web/${serviceId}/SEOTasks`;
      },
      wsSub: [],
    },
    component: () => import("@/views/pages/betterweb/semrush-tasks.vue"),
  },
  {
    path: ":serviceName",
    name: "web-base",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Web",
      routeChange(self, serviceId) {
        return `/Web/${serviceId}/${self.$route.params.serviceName}`;
      },
    },
    component: () => import("@/views/no-services-base.vue"),
  },
];

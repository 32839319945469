module.exports.whatsapp = {
  name: 'WhatsApp',
  serviceSelector: {
    defaultUrl: '/Communication/{ID}/WhatsappChat',
    storageKey: "whatsapp-chat",
    services: ['whatsapp'],
    label: "Select WhatsApp Service",
    autoSelectFirstService: true,
  }
};
module.exports.forms = {
  name: 'Form',
  serviceSelector: {
    defaultUrl: '/Communication/{ID}/Forms',
    storageKey: "forms",
    services: ['forms'],
    label: "Select Forms Service",
    autoSelectFirstService: true,
  }
};
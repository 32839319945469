window._boot.coreJS = new Date().getTime();

if (
  window.location.pathname !== "/" &&
  window.location.pathname.indexOf("/Auth") !== 0
)
  window.sessionStorage.setItem(
    "lastURL",
    (window.location.pathname || "/") +
      (window.location.search || "") +
      (window.location.hash || "")
  );

function randomUint() {
  return window.crypto.getRandomValues(new Uint32Array(1))[0];
}

function randomId() {
  let rann = "";
  while (rann.length < 64) rann += randomUint().toString(16);
  return rann;
}
if (window.localStorage.getItem("session") === null) {
  window._boot.session = randomId();
  window.localStorage.setItem("session", window._boot.session);
} else {
  window._boot.session = window.localStorage.getItem("session");
}

import Vue from "vue";
Vue.prototype.$_boot = window._boot;
window._boot = null;
delete window._boot;
import App from "./App.vue";
import router from "./router";
import store from "./store";
import cache from "./store/cache";
import ApiService from "./common/api.service";
import WSServiceEventBus from "./common/ws.service.eventBus";
import WSService from "./common/ws.service";
import Tools from "./common/Tools";
import Rules from "./common/vuefyRules";
import logging from "./common/logging";
import KTUtil from "./assets/js/util";
import version from "./version.js";

//import MockService from "./common/mock/mock.service";
/*import {
  VERIFY_AUTH,
  keycloakVue
} from "./store/auth.module";*/
import { UPDATE_LAYOUT_CONFIG } from "@/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
//import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import draggable from "vuedraggable";

// Remove this to disable mock API
//MockService.init();

// Ensure we checked auth before each page load.

/*

    const forceNAV = to => {
      if (to.fullPath === "/App/Dashboard") {
        if (
          (self.$root.layoutConfig().hidePages || []).indexOf("NS:Dashboard") >=
          0
        ) {
          for (let menuItem of self.menuItems) {
            if (!menuItem.page) {
              continue;
            }
            // pass the link prepend with slash
            self.$router.push(`/${menuItem.page}`);
            return;
          }
        }
      }
    };
    //self.$eventBus.on("force-nav-to-first-menu-item", forceNAV);

    this.$eventBus.on("page-navigation", forceNAV);
    forceNAV(this.$route);
*/
let vm = null;
router.beforeEach((to, from, next3) => {
  const next2 = () => {
    if (vm !== null) vm.$eventBus.emit("page-navigation", to);
    next3(true);
  };
  /*let next2 = v => {
    vm.$eventBus.emit("core-rerender-aside-hide-menu");
    next3(v);
  };*/
  let next = (v) => {
    vm.$eventBus.emit("core-rerender-aside");
    next2(v);
  };
  if (to.path === "/") return next2();
  if (to.path === "/404") return next2();
  if (to.path.indexOf("/Auth/") === 0) return next2();
  //if (to.path === "/dashboard") return next2("/MyAccount/Services");
  if (to.meta !== undefined && to.meta !== null && to.meta.public === true)
    return next2();

  if (vm !== undefined && vm !== null) {
    vm.$log.log(`Navigate: [${from.fullPath}] to [${to.fullPath}]`);

    //vm.$eventBus.emit('page-navigation', to);
    /*vm.$nextTick(() => {
      store
        .dispatch(VERIFY_AUTH)
        .then(x => {
          if (!x) {
            return router.push("/Authenticate");
          }
          vm.$root.updateServicesPage();
          //vm.$eventBus.emit('page-navigation', to)
          //next();
        })
        .catch(() => {});
    });*/
    //console.log(vm.$root.clientId)
    if (vm.$root.clientId === null) {
      return next3("/Auth/Clients");
    }

    if (!vm.$root.permissionOkay(to)) next(false);
    if (!vm.$root.serviceOkay(to)) next(false);
    //Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    // if (to.fullPath === "/App/Dashboard") {
    //   //return;
    //   //console.log(vm.menuItems)
    //   if (
    //     (vm.$root.layoutConfig().hidePages || []).indexOf("NS:Dashboard") >=
    //     0
    //   ) {
    //     for (let menuItem of vm.menuItems) {
    //       if (!menuItem.page) {
    //         continue;
    //       }
    //       // pass the link prepend with slash
    //       vm.$router.push(`/${menuItem.page}`);
    //       return;
    //     }
    //   }
    // }

    // Scroll page to top on every route change
    setTimeout(() => {
      vm.$scrollToTop();
    }, 100);
    next2();
  } else next3(false);
});

Vue.use(ApiService);
Vue.use(WSServiceEventBus);
Vue.use(logging);
Vue.use(Tools);
Vue.use(Rules);
Vue.use(WSService);
Vue.use(cache);
//Vue.use(keycloakVue);
Vue.component("draggable", draggable);

import { mapGetters } from "vuex";
import NetworksLib from "./views/pages/betternetworks/lib";

import VueTimeAgo from "vue-timeago";
import VuetifySnackbarQueue from "vuetify-snackbar-queue";
Vue.use(VueTimeAgo, {
  locale: "en",
});
Vue.use(VuetifySnackbarQueue);
Vue.use({
  install: (Vue) => {
    Vue.prototype.$scrollToTop = () => {
      KTUtil.scrollTop(0, 600);
    };
  },
});
Vue.use({
  install: (Vue) => {
    Vue.prototype.$appversion = version;
  },
});

new Vue({
  created() {
    vm = this;
    vm.$eventBus.on("page-loader-text", (e) => {
      vm.$data.loaderText = e;
    });
    vm.$apiSetup();
  },
  computed: {
    ...mapGetters(["clientId", "layoutConfig"]),
    inIframe() {
      return this.$tools.inIframe();
    },
  },
  data: {
    services: null,
    loaderText: "Rendering content",
  },
  mounted() {
    const self = this;
    self.$router.push("/Auth/Load");
    //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG).then(() => {

    self.$nextTick(async () => {
      await store.restored;
      self.$store
        .dispatch(UPDATE_LAYOUT_CONFIG)
        .then((hasChange) => {
          if (hasChange) {
            window.location.reload();
          }
          self.$nextTick(async () => {
            window.document.getElementsByTagName("title")[0].innerText =
              self.$store.getters.config.self.title;
            window.document
              .getElementById("favicon")
              .setAttribute(
                "href",
                self.$store.getters.config.self.logo.favicon ||
                  "/favicon-primary.ico"
              );
            await store.restored;
            //console.log('sessh', self.$wsSessionId())
            /*if (self.$tools.isNullOrUndefined(self.$wsSessionId()) || self.$wsSessionId() === '')
            self.$eventBus.on('ws-session', () => {
              self.$router.push("/Auth/Login");
              self.$eventBus.off('ws-session')
            });
          else*/
            self.$eventBus.emit(
              "ws-connect",
              self.$store.getters.config.servers.ws.url
            );
            self.$router.push("/Auth/Load");
          });
        })
        .catch((e) => {
          self.$router.push("/Auth/Error/" + e);
        });
      self.$eventBus.on("ws-auth", (authToken) => {
        if (authToken === false) {
          if (self.$route.path.indexOf("/Auth/") !== 0) {
            self.$router.push("/Auth/Load");
          }
        }
      });
      self.$eventBus.on("ws-crm-event", (ev) => {
        self.$eventBus.emit(`evu-${ev.action.split("-")[0]}`, ev.data);
        self.$eventBus.emit(`evu-${ev.action.split("-")[0]}-global`, ev.data);
        self.$eventBus.emit(`evu-${ev.action}`, ev.data);
        self.$eventBus.emit(`evu-${ev.action}-global`, ev.data);
        //self.$eventBus.emit( 'core-rerender-aside' );
        /* 
          changeType: postBody.changeType,
          entity: postBody.entity,
          entityId: postBody.entityId,
          eventName: postBody.eventName
        */
      });

      if (Vue.prototype.$log !== undefined)
        Vue.prototype.$log.info(`Load Nav On: ${self.$route.fullPath}`);
      //store.dispatch(VERIFY_AUTH);
      Vue.prototype.$_boot.mounted = new Date().getTime();

      NetworksLib.initUpdator(self);
      //this.updateServicesPage();
      /*this.$eventBus.on("cache-crm:services", () => {
        self.updateServicesPage();
      });*/
    });
  },
  methods: {
    updateServicesPage() {
      let self = this;
      self.$api
        .getWithCache(self, "crm", `${self.clientId}/services`, "crm:services")
        .then((sData) => {
          self.$data.services = sData;

          self.$nextTick(() => {
            let lastURL = window.sessionStorage.getItem("lastURL");
            if (lastURL !== undefined && lastURL !== null && lastURL !== "") {
              window.sessionStorage.removeItem("lastURL");
              return router.push(lastURL);
            }
            self.$eventBus.emit("core-rerender-aside");
          });
        })
        .catch((err) => {
          self.$log.error(err);
        });
    },
    permissionOkay(menuItem) {
      if (this.$tools.isNullOrUndefined(menuItem.roles)) return true;
      //if ( this.$tools.isNullOrUndefined( this.roles ) ) return false;

      return false; /// ROLES NOT SETUP
      /*if (this.$tools.isNullOrUndefined(menuItem.roles)) return true;
      if (this.$tools.isNullOrUndefined(this.currentUser)) return false;
      if (this.$tools.isNullOrUndefined(this.currentUser._token)) return false;
      if (this.$tools.isNullOrUndefined(this.currentUser._token)) return false;

      return false;*/
    },
    serviceOkay() {
      //menuItem) {
      return true;
      /*if (this.$tools.isNullOrUndefined(menuItem.services)) return true;
      if (this.$tools.isNullOrUndefined(this.services)) return false;

      let result = false;
      for (let service of this.services) {
        let thisOkay = false;
        for (let menuService of menuItem.services) {
          let iThisOkay = true;
          for (let menuServiceItem of Object.keys(menuService)) {
            if (
              menuService[menuServiceItem].indexOf(service[menuServiceItem]) < 0
            )
              iThisOkay = false;
          }
          if (iThisOkay) {
            thisOkay = true;
          }
        }
        if (thisOkay) {
          result = true;
          break;
        }
      }

      return result;*/
    },
  },
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

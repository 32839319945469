//import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
//import JwtService from "@/common/jwt.service";
//import CONFIG from "@/common/config";

/**
 * Service to call HTTP request via Axios
 */
let serviceStatuses = {};
export default {
  install(Vue) {
    let self = null;
    Vue.use(VueAxios, axios.create());
    Vue.prototype.$serviceStatus = function(servicename) {
      return serviceStatuses[servicename] || false;
    }
    const runSysServiceChecker = () => {
      for (let service of Object.keys(self.$store.getters.config.servers)) {
        self.$api.get(service, 'health', {
          timeout: 1000
        }).then(x => {
          if (x.status === 200 && x.data.alive === true) {
            serviceStatuses[service] = true;
            self.$eventBus.emit('api-service-state', {
              service,
              state: true
            });
          } else {
            serviceStatuses[service] = false;
            self.$eventBus.emit('api-service-state', {
              service,
              state: false
            });
          }
        }).catch(() => {
          serviceStatuses[service] = false;
          self.$eventBus.emit('api-service-state', {
            service,
            state: false
          });
        });
      }
    }
    Vue.prototype.$apiSetup = function() {
      self = this;

      self.$eventBus.on('ws-client-changed', () => {
        self.$cache.reset();
        runSysServiceChecker();
      });
      self.$eventBus.on('ws-force-service-recheck', () => {
        //self.$cache.reset();
        runSysServiceChecker();
      });
      self.$eventBus.on('cron-hour', () => {
        //self.$cache.reset();
        runSysServiceChecker();
      });
    }
    const getAxiosHeaders = (headers, auth = true, contentType = true, accept = true) => {
      headers = headers || {};
      //headers.Session = Vue.prototype.$_boot.session;
      headers["tenant-id"] = self.$store.getters.tenantId;
      headers["app-id"] = self.$store.getters.appId;
      if (self.$store.getters.isAuthenticated && auth)
        headers['Authorization'] = `BSBAuth ${self.$store.getters.token}`;
      if (Object.keys(headers).map(x => x.toLowerCase()).indexOf('content-type') < 0 && contentType)
        headers["Content-Type"] = "application/json";
      if (Object.keys(headers).map(x => x.toLowerCase()).indexOf('accept') < 0 && accept)
        headers["Accept"] = "application/json";
      return headers;
    }
    Vue.prototype.$api = {
      getAxiosHeaders,
      /*getBaseURL(serviceName) {
        if (serviceName === undefined || serviceName === null)
          return CONFIG().API_URL;
        let serviceNameSplit = serviceName.split("/");
        let actUrl = CONFIG()[
          `SERVICE_${serviceNameSplit.splice(0, 1)}`.toUpperCase()
        ];
        if (actUrl === undefined || actUrl === null)
          return CONFIG().API_URL + "/" + serviceName;
        return (
          actUrl +
          (serviceNameSplit.length > 0 ? "/" + serviceNameSplit.join("/") : "")
        );
      },*/
      getBaseURL(serviceName) {
        //this.setHeader();
        return self.$store.getters.config.servers[serviceName].url;
      },

      getAsset(filename) {
        return Vue.axios({
          method: 'get',
          url: `/assets/${filename}`
        });
      },

      /**
       * Set the default HTTP request headers
       */
      /*setHeader() {
        if (self.$store.getters.isAuthenticated)
          Vue.axios.defaults.headers.common[
            "Authorization"
          ] = `BSBAuth ${self.$store.getters.token}`;
        Vue.axios.defaults.headers.common[
          "tenant-id"
        ] = self.$store.getters.tenantId;
        Vue.axios.defaults.headers.common["Session"] = Vue.prototype.$_boot.session;
      },*/

      query(resource, params) {
        return Vue.axios({
            method: 'get',
            url: self.$api.getBaseURL(resource),
            params,
            headers: getAxiosHeaders()
          })
          .catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
          });
      },

      triggerDownloadFile(path, filename, resource = undefined, noAuth = false) {
        let destUrl = path;
        if (resource !== undefined && resource !== null) {
          destUrl = self.$api.getBaseURL(resource) + path;
        } else if (destUrl.indexOf('http') !== 0) {
          destUrl = self.$api.getBaseURL(destUrl);
        }
        return (noAuth ? axios : Vue.axios)({
            method: "get",
            url: destUrl,
            responseType: "arraybuffer"
          })
          .catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.id = `api-service-file-download-trigger-${new Date().getTime()}`;
            link.href = url;
            link.setAttribute("download", filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.getElementById(link.id).remove();
            }, 10000);
          });
      },

      /**
       * Send the GET HTTP request
       * @param resource
       * @param slug
       * @returns {*}
       */
      get(resource, slug = "", moreOpts = {}) {
        /*console.log(
          `get:::${resource} ${slug} = ${`${self.$api.getBaseURL(
            resource
          )}/${slug}`}`
        );*/
        return new Promise((resolve, reject) => {
          let sbLink = self.$api.getBaseURL(resource);
          //console.log(resource, sbLink, slug)
          if (sbLink.indexOf('undefined') >= 0) return reject('invalid url');
          Vue.axios({
              method: 'get',
              url: `${sbLink}/${slug}`,
              headers: (moreOpts || {}).overideheaders || getAxiosHeaders(((moreOpts || {}).headers || {})),
              ...(moreOpts || {})
            })
            .then(resolve)
            .catch(error => {
              // console.log(error);
              reject(error);
              //throw new Error(`[KT] ApiService ${error}`);
            });
        })
      },
      hasCache(self, cacheKey) {
        return self.$cache.has(cacheKey) || false;
      },
      forceCacheSet(self, cacheKey, data) {
        self.$cache.set(cacheKey, data);
        self.$eventBus.emit(`cache-${cacheKey}`);
      },
      getWithCache(self, path1, path2, cacheKey, bumpCache = false) {
        return new Promise((resolve, reject) => {
          if (bumpCache) {
            self.$log.log(`force request: ${cacheKey}: ${path1}/${path2}`);
            return self.$api.get(path1, path2)
              .then(({
                data
              }) => {
                self.$cache.set(cacheKey, data);
                self.$eventBus.emit(`cache-${cacheKey}`, data);
                resolve(data);
              })
              .catch(({
                response
              }) => {
                reject(response);
              });
          }
          self.$cache
            .get(cacheKey)
            .then((cacheOfServices) => {
              self.$log.log(`get request: ${cacheKey}: ${path1}/${path2} ? ${cacheOfServices !== null} [${typeof cacheOfServices}]`);
              //console.log('CacheGET: ' + cacheKey + ' ? ' + (cacheOfServices !== null))
              if (cacheOfServices !== null) {
                return resolve(cacheOfServices);
              }
              //console.log(`RE:::${path1} ${path2}`);
              self.$api.get(path1, path2)
                .then(({
                  data
                }) => {
                  self.$cache.set(cacheKey, data);
                  self.$eventBus.emit(`cache-${cacheKey}`, data);
                  resolve(data);
                })
                .catch(({
                  response
                }) => {
                  reject(response);
                });
            })
            .catch(x => {
              reject(x);
            });
        });
      },

      /**
       * Set the POST HTTP request
       * @param resource
       * @param params
       * @returns {*}
       */
      post(resource, params) {
        return Vue.axios({
          method: 'post',
          url: `${self.$api.getBaseURL(resource)}`,
          params,
          headers: getAxiosHeaders()
        })
      },
      post2(resource, slug, data) {
        //console.log(self)
        return Vue.axios({
          method: 'POST',
          url: `${self.$api.getBaseURL(resource)}/${slug}`,
          headers: getAxiosHeaders(),
          data
        })
      },

      /**
       * Send the UPDATE HTTP request
       * @param resource
       * @param slug
       * @param params
       * @returns {IDBRequest<IDBValidKey> | Promise<void>}
       */
      update(resource, slug, params) {
        return Vue.axios({
          method: 'patch',
          url: `${self.$api.getBaseURL(resource)}/${slug}`,
          params,
          headers: getAxiosHeaders()
        })
      },
      update2(resource, slug, params, data) {
        return Vue.axios({
          method: 'patch',
          url: `${self.$api.getBaseURL(resource)}/${slug}`,
          params,
          data,
          headers: getAxiosHeaders()
        })
      },

      /**
       * Send the PUT HTTP request
       * @param resource
       * @param params
       * @returns {IDBRequest<IDBValidKey> | Promise<void>}
       */
      put(resource, params) {
        return Vue.axios({
          method: 'put',
          url: `${self.$api.getBaseURL(resource)}`,
          params,
          headers: getAxiosHeaders()
        });
      },
      put2(resource, url, params, data) {
        return Vue.axios({
          method: 'put',
          url: `${self.$api.getBaseURL(resource)}/${url}`,
          params,
          data,
          headers: getAxiosHeaders()
        });
      },

      /**
       * Send the DELETE HTTP request
       * @param resource
       * @returns {*}
       */
      delete(resource) {
        return Vue.axios({
          method: 'delete',
          url: self.$api.getBaseURL(resource),
          headers: getAxiosHeaders()
        }).catch(error => {
          // console.log(error);
          throw new Error(`[RWV] ApiService ${error}`);
        });
      }
    };
    //Vue.axios.defaults.baseURL = CONFIG().API_URL;

    /*Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      function(error) {
        const originalRequest = error.config;
        //console.log(`req: ${error.request.url} = ${error.response.status}`)
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          self.$api.setHeader();
          return self.$api.get("webapi/user")
            .then(({
              data
            }) => {
              self.$api.setHeader();
              Vue.prototype.$eventBus.emit(`ws-auth`, data.token);
              return axios(originalRequest);
            })
            .catch(() => {
              Vue.prototype.$eventBus.emit(`ws-auth`, false);
            });
          */
    /*return axios.post('/auth/token', {
                  "refresh_token": localStorageService.getRefreshToken()
                })
                .then(res => {
                  if (res.status === 201) {
                    // 1) put token to LocalStorage
                    localStorageService.setToken(res.data);

                    // 2) Change Authorization header
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();

                    // 3) return originalRequest object with Axios.
                    return axios(originalRequest);
                  }
                })*/
    /*
            }
            if (!(error.response.status >= 200 && error.response.status < 300))
              return Promise.reject(error);
          }
        );*/
  },
};
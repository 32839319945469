export default {
  serviceSelector: {
    defaultUrl: '/Telecoms/{ID}/CallDetailRecords',
    storageKey: "telecoms",
    services: [{
      servicePlanId: [39],
      status: [1]
    }],
    label: "Select Telecoms Service",
    autoSelectFirstService: true,
  }
};
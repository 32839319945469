import extraMeta from './routerConfig'

export default [{
    path: "CCTV/:serviceId/Live",
    name: "bettersecurity-cctv-live",
    meta: {
      //wsSub: ['cctv-live'],
      ...extraMeta,
      menuAssistantPath: "/Security/CCTV/Live",
      routeChange(self, serviceId) {
        return `/Security/CCTV/${serviceId}/Live`;
      }
    },
    component: () => import("@/views/pages/security/cctv/live.vue")
  },
  {
    path: "CCTV/:serviceName",
    name: "bettersecurity-cctv",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Security/CCTV",
      routeChange(self, serviceId) {
        return `/Security/CCTV/${serviceId}/${self.$route.params.serviceName}`;
      }
    },
    component: () => import("@/views/blank-base.vue")
  }/*,
  {
    path: "CCTV",
    name: "bettersecurity-cctv",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Security",
      routeChange(self, serviceId) {
        return `/Security/CCTV/${serviceId}/CCTV`;
      }
    },
    component: () => import("@/views/blank-base.vue")
  }*/
];
const LRU = require("lru-cache");

let $cache = null;
let defaultMaxAge = 1000 * 60 * 60;
export default {
  install(Vue) {
    // eslint-disable-line no-unused-vars
    $cache = new LRU({
      max: 50,
      /*length: function(n, key) {
        return n * 2 + key.length;
      },*/
      dispose: (key/*, value*/) => {
        //console.log('CDISPOSE:', key, value)
        /*if (
          n !== undefined &&
          n !== null &&
          n.close !== undefined &&
          typeof n.close === "function"
        )
          n.close();*/
        window.localStorage.removeItem(`cache-${key}`);
        window.localStorage.removeItem(`cache-${key}-type`);
        window.localStorage.setItem('record-cache', new Date().getTime().toString());
      },
      maxAge: defaultMaxAge,
      ttlAutopurge: true
    });
    Vue.prototype.$cache = this;
    const timeNow = new Date().getTime() - (defaultMaxAge);
    let isCacheStale = true;
    let timeFromCache = window.localStorage.getItem('record-cache') || null;
    if (timeFromCache !== undefined && timeFromCache !== null && timeFromCache !== '') {
      timeFromCache = Number.parseInt(timeFromCache);

      if (timeFromCache > timeNow)
        isCacheStale = false;
    }
    if (isCacheStale) {
      Vue.prototype.$cache.reset();
    } else {
      for (let lsKey of Object.keys(window.localStorage)) {
        if (lsKey.indexOf('cache-') !== 0) continue;
        if (lsKey.endsWith('-type')) continue;
        $cache.set(lsKey.replace('cache-', ''), window.localStorage.getItem(`${lsKey}-type`));
      }
    }
    Vue.prototype.$eventBus.on("ws-auth", authToken => {
      if (authToken === false) Vue.prototype.$cache.reset();
    });
    //$cache.reset();
    Vue.prototype.$eventBus.on("cron-hour", () => {
      Vue.prototype.$cache.prune();
    });
    if (window.location.hostname === '10.1.3.253') {
      Vue.prototype.$eventBus.on("cron-minute", () => {
        Vue.prototype.$cache.reset();
      });
      Vue.prototype.$cache.reset();
    }
  },

  reset() {
    //console.log('CACHE RESET');
    for (let lsKey of Object.keys(window.localStorage)) {
      if (lsKey.indexOf('cache-') !== 0) continue;
      window.localStorage.removeItem(lsKey);
    }
    return $cache.reset();
  },

  set(key, value, maxAge) {
    let isString = true;
    if (typeof value !== "string") {
      value = JSON.stringify(value);
      isString = false;
    }
    try {
      window.localStorage.setItem(`cache-${key}`, value);
      window.localStorage.setItem(`cache-${key}-type`, isString ? 'S' : 'O');
      $cache.set(key, isString ? 'S' : 'O', maxAge || defaultMaxAge);
      window.localStorage.setItem('record-cache', new Date().getTime().toString());
      //console.log('SET CACHE: ' + key, value, maxAge || defaultMaxAge)
    } catch (e) {
      //$cache.prune();
      //console.log(e)
    }
    return value;
  },

  get: key =>
    new Promise((resolve, reject) => {
      try {
        window.localStorage.setItem('record-cache', new Date().getTime().toString());
        let cacheInfo = $cache.get(key);
        //console.log('TGC:', key, cacheInfo)
        if (cacheInfo === undefined || cacheInfo === null) return resolve(null);
        if (cacheInfo === 'O') {
          return resolve(JSON.parse(window.localStorage.getItem(`cache-${key}`)));
        }
        resolve(window.localStorage.getItem(`cache-${key}`));
      } catch (Exc) {
        reject(Exc);
      }
    }),

  peek: key =>
    new Promise((resolve, reject) => {
      try {
        let cacheInfo = $cache.peek(key);
        if (cacheInfo === undefined || cacheInfo === null) return resolve(cacheInfo);
        if (cacheInfo === 'O') {
          return resolve(JSON.parse(window.localStorage.getItem(`cache-${key}`)));
        }
        resolve(window.localStorage.getItem(`cache-${key}`));
      } catch (Exc) {
        reject(Exc);
      }
    }),

  del(key) {
    return $cache.del(key);
  },

  has(key) {
    return $cache.has(key);
  },

  keys() {
    return $cache.keys();
  },

  values() {
    return $cache.values();
  },

  dump() {
    return $cache.dump();
  },

  load(cacheEntriesArray) {
    return $cache.load(cacheEntriesArray);
  },

  prune() {
    return $cache.prune();
  }
};
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    light: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#0091ea",
        secondary: "#e8ecfa",
        accent: "#0091ea",
        success: "#01c853",
        info: "#36a3f7",
        warning: "#ffc300",
        error: "#ff1844"
      },
      dark: {
        primary: "#0091ea",
        secondary: "#e8ecfa",
        accent: "#0091ea",
        success: "#01c853",
        info: "#36a3f7",
        warning: "#ffc300",
        error: "#ff1844"
      }
      /*light: {
        primary: "#5867dd",
        secondary: "#e8ecfa",
        accent: "#5d78ff",
        error: "#fd397a",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822"
      }*/
    }
  }
});

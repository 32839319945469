import Vue from "vue";
//import ApiService from '@/common/api.service';
//import JwtService from "@/common/jwt.service";
//import createAuth0Client from "@auth0/auth0-spa-js";

//let instance;

/** Returns the current instance of the SDK */
//export const getInstance = () => instance;

// Create a simple Vue plugin to expose the wrapper object throughout the application
/*export const keycloakVue = {
  install(Vue) {
    Vue.prototype.$auth = null;
  }
};*/


// action types
export const VERIFY_AUTH = "verifyAuth";
export const DO_LOGIN = "login";
export const DO_CLIENT_CHANGE = "selectClient";
export const FORCE_REFRESH_TOKEN = "forceAuthRefresh";
//export const TOKEN_LOGIN = "tokenLogin";
export const LOGOUT = "logout";
//export const REGISTER = "register";
//export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_CLIENT = "setClient";
//export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  clientId: null
  //isAuthenticated: false,
};

let renewInterval = null;
let booted = false;

const getters = {
  clientId(state) {
    return state.clientId;
  },
  currentUser(state) {
    if (state.user.name === undefined) return null;
    return {
      client: (state.user.clients || {})[state.clientId],
      name: state.user.name,
      surname: state.user.surname,
      userId: state.user.userId,
      email: state.user.email,
      clients: state.user.clients,
    };
  },
  userId(state) {
    return state.user.userId || null;
  },
  isAuthenticated(state) {
    if (state.user === undefined) return false;
    return state.user.token !== undefined;
  },
  token(state) {
    return state.user.token;
  }
};

const actions = {
  [DO_LOGIN](context, user) {
    context.commit(SET_AUTH, user);
  },
  [DO_CLIENT_CHANGE](context, clientId) {
    context.commit(SET_CLIENT, clientId);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    if (renewInterval !== null) {
      clearInterval(renewInterval);
      renewInterval = null;
    }
  },
  [FORCE_REFRESH_TOKEN](context) {
    if (renewInterval !== null) {
      clearInterval(renewInterval);
      renewInterval = null;
    }
    booted = false;
    context.dispatch(VERIFY_AUTH);
  },

  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      try {
        if (!context.getters.isAuthenticated) {
          context.dispatch(LOGOUT);
          booted = true;
          return resolve(false);
        } else {
          Vue.prototype.$eventBus.emit(`ws-auth`, context.state.user.token);
          let timeLeftOfToken = context.state.user.expires - new Date().getTime();
          if (timeLeftOfToken < 10000) { // 10s
            context.dispatch(LOGOUT);
            booted = true;
            return resolve(false);
          }
          if (renewInterval === null) {
            renewInterval = setInterval(async () => {
              await context.dispatch(VERIFY_AUTH);
            }, 30000); //60000);
          }
          if (!booted || timeLeftOfToken < (1000 * 60 * 5)) { // renew
            Vue.prototype.$api.update2("auth", "auth", undefined, {})
              .then(({
                data
              }) => {
                context.commit(SET_AUTH, data);
                booted = true;
                return resolve(true);
              })
              .catch(() => {
                //context.commit(SET_ERROR, response.data.errors);
                context.dispatch(LOGOUT);
                booted = true;
                return resolve(false);
              });
          }
          booted = true;
          return resolve(true);
        }
      } catch (exc) {
        //console.error(exc);
        booted = true;
        return reject(exc);
      }
    });
  }
  /*[UPDATE_USER](context, payload) {
    const {
      email,
      username,
      password,
      image,
      bio
    } = payload;
    const user = {
      email,
      username,
      bio,
      image
    };
    if (password) {
      user.password = password;
    }

    return ApiService.put("webapi/user", user).then(({
      data
    }) => {
      context.commit(SET_AUTH, data);
      Vue.prototype.$eventBus.emit(`ws-auth`, data.token);
      return data;
    });
  }*/
};

const mutations = {
  /*[SET_ERROR](state, error) {
    state.errors = error;
  },*/
  [SET_AUTH](state, user) {
    //state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    Vue.prototype.$eventBus.emit(`ws-auth`, user.token);
    if (Object.keys(user.clients).indexOf(`${state.clientId}`) < 0)
      Vue.prototype.$eventBus.emit(`ws-client-changed`, false);
    //Vue.prototype.$eventBus.emit(`ws-client-changed`, state.clientId);
    //JwtService.saveToken(conf.token);
    //Vue.prototype.$eventBus.emit(`ws-auth`, data.token);
  },
  [SET_CLIENT](state, clientId) {
    //state.isAuthenticated = true;
    state.clientId = clientId;
    state.errors = {};
    Vue.prototype.$eventBus.emit(`ws-client-changed`, clientId);
    if (state.clientId != clientId)
      Vue.prototype.$cache.reset();
    //JwtService.saveToken(conf.token);
    //Vue.prototype.$eventBus.emit(`ws-auth`, data.token);
  },
  [PURGE_AUTH](state) {
    //state.isAuthenticated = false;
    state.clientId = null;
    state.user = {};
    state.errors = {};
    if (state.user.token !== undefined) {
      Vue.prototype.$eventBus.emit(`ws-fdisconnect`, false);
    }
    Vue.prototype.$eventBus.emit(`ws-auth`, false);
    Vue.prototype.$eventBus.emit(`ws-client-changed`, false);
    Vue.prototype.$cache.reset();
    //JwtService.destroyToken();
    //JwtService.destroyUser();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
const extraMeta = {
  name: "Advert",
  serviceSelector: {
    storageKey: "advertising",
    services: ['adbudManager'],
    label: "Select AD Service",
    autoSelectFirstService: true,
    nameFunc(self, service) {
      if (`${service.note}`.indexOf("NAME:") < 0) return null;
      let propsList = `${service.note}`.split(" ");

      if (propsList.length < 2) return null;

      let domainname = null;
      for (let prop of propsList) {
        if (prop.indexOf("NAME:") === 0) {
          domainname = prop.split(":")[1].trim();
          break;
        }
      }

      if (self.$tools.isNullOrUndefined(domainname)) return null;
      return domainname;
    }
  }
};

export default [{
  path: ":serviceId/A1Dash",
  name: "advertising-adbud-dash",
  meta: {
    ...extraMeta,
    menuAssistantPath: "/Advertising/A1Dash",
    routeChange(self, serviceId) {
      return `/Advertising/${serviceId}/A1Dash`;
    },
    wsSub: []
  },
  component: () => import("@/views/pages/advertising/dash.vue")
},{
    path: ":serviceId/A1Stats",
    name: "advertising-adbud-stats",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Advertising/A1Stats",
      routeChange(self, serviceId) {
        return `/Advertising/${serviceId}/A1Stats`;
      },
      wsSub: []
    },
    component: () => import("@/views/pages/advertising/stats.vue")
  },{
    path: ":serviceId/A1ADs",
    name: "advertising-adbud-ads",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Advertising/A1ADs",
      routeChange(self, serviceId) {
        return `/Advertising/${serviceId}/A1ADs`;
      },
      wsSub: ['advertising-customer-updated']
    },
    component: () => import("@/views/pages/advertising/ads.vue")
  },{
    path: ":serviceId/A1Config",
    name: "advertising-adbud-config",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Advertising/A1Config",
      routeChange(self, serviceId) {
        return `/Advertising/${serviceId}/A1Config`;
      },
      wsSub: ['advertising-customer-updated']
    },
    component: () => import("@/views/pages/advertising/config.vue")
  },
  {
    path: ":serviceName",
    name: "advertising-base",
    meta: {
      ...extraMeta,
      menuAssistantPath: "/Advertising",
      routeChange(self, serviceId) {
        return `/Advertising/${serviceId}/${self.$route.params.serviceName}`;
      }
    },
    component: () => import("@/views/no-services-base.vue")
  }
];
